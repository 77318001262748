import React from "react";
import AboutUsIntroImg from "../assets/img/about intro.png";
const AboutUsPageIntro = () => {
  return (
    <div className="relative pt-16">
      <img alt="/" src={AboutUsIntroImg} />
      <div className="absolute left-[10%] bottom-[10%] hd-style text-white text-sm screen500px:text-lg screen600px:text-xl sm:text-4xl md:text-6xl lg:text-9xl">QBA STUDIO</div>
    </div>
  );
};

export default AboutUsPageIntro;
