import React from "react";

const BrainStorm = () => {
  return (
    <svg
      width="111"
      height="98"
      viewBox="0 0 111 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M84.3572 56.2173C84.7303 56.2173 85.1242 56.1137 85.4558 55.9065C86.2021 55.4506 86.5752 54.5388 86.3887 53.6685L84.4608 44.8822H85.7461C92.5661 44.8822 98.8887 38.4997 98.8887 31.4333V18.8134C98.8887 11.975 92.6698 5.90337 85.7461 5.90337H78.8638C77.7237 5.90337 76.7908 6.83587 76.7908 7.9756C76.7908 9.11534 77.7237 10.0478 78.8638 10.0478H85.7461C90.3895 10.0478 94.7427 14.2545 94.7427 18.8134V31.4333C94.7427 36.158 90.2237 40.7377 85.7461 40.7377H81.8903C81.2684 40.7377 80.6673 41.0485 80.2734 41.5459C79.8796 42.0432 79.7344 42.6856 79.8588 43.3073L81.0819 48.9023L71.4219 41.2143C71.0487 40.9242 70.5927 40.7377 70.1366 40.7377H66.426C65.2859 40.7377 64.353 41.6702 64.353 42.8099C64.353 43.9497 65.2859 44.8822 66.426 44.8822H69.4111L83.0719 55.7407C83.4451 56.0516 83.9011 56.2173 84.3572 56.2173Z"
        fill="black"
      />
      <path
        d="M48.7028 53.7298C49.3247 53.7298 49.9466 53.4189 50.3405 52.9009L60.498 39.9079H62.5088C69.3288 39.9079 75.6514 33.5254 75.6514 26.4591V13.8392C75.6514 7.00077 69.4325 0.929108 62.5088 0.929108H42.1315C35.0005 0.929108 28.3877 7.22872 28.3877 13.8392V26.4591C28.3877 33.2768 35.1249 39.9079 42.1315 39.9079H45.0751L46.6298 51.9062C46.7335 52.7351 47.3346 53.4396 48.1431 53.6469C48.3504 53.6883 48.537 53.7298 48.7028 53.7298ZM62.5295 5.09431C67.173 5.09431 71.5262 9.30095 71.5262 13.8599V26.4798C71.5262 31.2045 67.0071 35.7842 62.5295 35.7842H59.503C58.8604 35.7842 58.2592 36.095 57.8653 36.6131L50.1332 46.4976L48.9723 37.6077C48.8479 36.5716 47.9566 35.7842 46.9201 35.7842H42.1522C37.4259 35.7842 32.5544 31.0387 32.5544 26.4798V13.8599C32.5544 9.46673 37.2393 5.09431 42.1522 5.09431H62.5295Z"
        fill="black"
      />
      <path
        d="M26.3134 56.9418C26.8316 56.9418 27.3499 56.7138 27.7437 56.3408L38.0671 46.5392H40.7205C41.8606 46.5392 42.7935 45.6067 42.7935 44.4669C42.7935 43.3272 41.8606 42.3947 40.7205 42.3947H37.2379C36.699 42.3947 36.2014 42.6226 35.8076 42.9956L29.3399 49.1502L30.024 44.8399C30.1277 44.239 29.9411 43.5966 29.5472 43.1407C29.1534 42.6848 28.5729 42.3947 27.9717 42.3947H19.9701C15.451 42.3947 10.9942 37.67 10.9942 32.9867V20.3668C10.9942 15.87 15.2023 11.7048 19.9701 11.7048H24.1368C25.2769 11.7048 26.2097 10.7723 26.2097 9.63259C26.2097 8.49286 25.2769 7.56035 24.1368 7.56035H19.9701C12.9013 7.56035 6.84822 13.5698 6.84822 20.3668V32.9867C6.84822 39.9702 13.1708 46.5392 19.9701 46.5392H25.5464L24.2819 54.5173C24.1368 55.3876 24.5721 56.2787 25.3598 56.6931C25.6708 56.8589 25.9817 56.9418 26.3134 56.9418Z"
        fill="black"
      />
      <path
        d="M73.661 97.9713C73.8061 97.9713 73.9304 97.9505 74.0755 97.9298C75.1949 97.7019 75.9205 96.6036 75.6925 95.4846C74.884 91.5473 72.9354 88.0867 70.4479 86.2838C68.3749 84.7711 65.0581 82.989 57.8235 82.989H53.8434C46.6087 82.989 43.292 84.7504 41.219 86.2838C38.69 88.1281 36.7829 91.4437 35.9744 95.3602C35.7464 96.4792 36.4719 97.5775 37.5913 97.8055C38.7107 98.0334 39.8094 97.3081 40.0374 96.1891C40.6179 93.3087 41.986 90.8428 43.6651 89.6201C45.3442 88.3975 47.8525 87.1334 53.8434 87.1334H57.8235C63.8351 87.1334 66.3434 88.3975 68.0017 89.6201C69.6394 90.822 71.0283 93.3709 71.6294 96.2927C71.8367 97.2874 72.7074 97.9713 73.661 97.9713Z"
        fill="black"
      />
      <path
        d="M55.8541 81.0835C62.4254 81.0835 67.7529 75.7372 67.7529 69.1889C67.7529 62.6199 62.4046 57.2943 55.8541 57.2943C49.3035 57.2943 43.9553 62.6406 43.9553 69.1889C43.9345 75.7372 49.2828 81.0835 55.8541 81.0835ZM55.8541 61.418C60.1244 61.418 63.607 64.8994 63.607 69.1682C63.607 73.437 60.1244 76.9183 55.8541 76.9183C51.5838 76.9183 48.1012 73.437 48.1012 69.1682C48.0805 64.8994 51.563 61.418 55.8541 61.418Z"
        fill="black"
      />
      <path
        d="M2.86925 97.8687C3.84355 97.8687 4.69347 97.1849 4.90077 96.2109C5.4812 93.3305 6.84936 90.8646 8.52846 89.6419C10.1868 88.4193 12.7158 87.1553 18.7067 87.1553H22.6868C28.7191 87.1553 31.3104 88.5022 32.8651 89.6419C33.7979 90.3258 35.0832 90.1186 35.7672 89.2068C36.4513 88.2743 36.244 86.9687 35.3112 86.2849C33.2382 84.7722 29.9215 83.0108 22.6868 83.0108H18.7067C11.472 83.0108 8.15532 84.7722 6.08235 86.3056C3.55333 88.1499 1.64619 91.4655 0.837734 95.382C0.609707 96.501 1.33525 97.5993 2.45466 97.8273C2.59977 97.848 2.72414 97.8687 2.86925 97.8687Z"
        fill="black"
      />
      <path
        d="M20.6964 81.0835C27.2677 81.0835 32.5952 75.7372 32.5952 69.1889C32.5952 62.6199 27.247 57.2943 20.6964 57.2943C14.1458 57.2943 8.79754 62.6406 8.79754 69.1889C8.77681 75.7372 14.1251 81.0835 20.6964 81.0835ZM20.6964 61.418C24.9667 61.418 28.4493 64.8994 28.4493 69.1682C28.4493 73.437 24.9667 76.9183 20.6964 76.9183C16.4261 76.9183 12.9435 73.437 12.9435 69.1682C12.9227 64.8994 16.4054 61.418 20.6964 61.418Z"
        fill="black"
      />
      <path
        d="M108.881 97.8676C109.026 97.8676 109.15 97.8469 109.295 97.8262C110.415 97.5982 111.14 96.5 110.912 95.381C110.104 91.4644 108.197 88.1281 105.668 86.2838C103.595 84.7711 100.278 82.989 93.0432 82.989H89.0631C81.8285 82.989 78.5117 84.7504 76.4387 86.2838C75.5059 86.9677 75.3193 88.2732 75.9827 89.185C76.6668 90.1175 77.952 90.2832 78.8848 89.6201C80.564 88.3975 83.0723 87.1334 89.0631 87.1334H93.0432C99.0548 87.1334 101.563 88.3975 103.221 89.6201C104.901 90.8428 106.248 93.3087 106.849 96.1891C107.036 97.1838 107.906 97.8676 108.881 97.8676Z"
        fill="black"
      />
      <path
        d="M91.0533 81.0835C97.6246 81.0835 102.952 75.7372 102.952 69.1889C102.952 62.6199 97.6039 57.2943 91.0533 57.2943C84.5027 57.2943 79.1545 62.6406 79.1545 69.1889C79.1545 75.7372 84.482 81.0835 91.0533 81.0835ZM91.0533 61.418C95.3236 61.418 98.8062 64.8994 98.8062 69.1682C98.8062 73.437 95.3236 76.9183 91.0533 76.9183C86.783 76.9183 83.3004 73.437 83.3004 69.1682C83.3004 64.8994 86.783 61.418 91.0533 61.418Z"
        fill="black"
      />
    </svg>
  );
};

export default BrainStorm;
