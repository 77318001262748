import React from "react";
import styles from "../AboutUsDescriptionItem.module.scss";

const AboutUsVisionCarousel = ({ visionCarousel }) => {
  return (
    <div className="h-[450px]">
      <a
        href="/"
        className={`flex flex-col items-center gap-10 border rounded-2xl border-black dark:border-white hover:bg-emerald hover:border-0 hover:text-white h-full p-12 ${styles.title}`}
      >
        <div className="flex justify-center items-center h-40 w-36">
          {visionCarousel.img}
        </div>
        <div className="hd-style text-2xl text-center">{visionCarousel.title}</div>
        <div className="p-style text-xs text-center dark:text-white">
          {visionCarousel.description}
        </div>
      </a>
    </div>
  );
};

export default AboutUsVisionCarousel;
