import React from "react";

const EvolweIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 217 41 " fill="none">
      <path
        fill="#fff"
        d="M.364 23.585c-.899-5.541-.387-12.6 4.458-16.142 6.277-4.59 17.987-1.944 21.414 5.22a12.248 12.248 0 0 0-4.573-3.147C11.539 5.518 2.04 13.863.364 23.585Z"
      />
      <path
        fill="#fff"
        d="M16.852 40.087c-5.526-.853-12.054-3.523-13.911-9.244C.533 23.43 6.657 13.065 14.502 12.006a12.252 12.252 0 0 0-4.394 3.395c-6.915 8.432-1.947 20.08 6.744 24.686Z"
      />
      <path
        fill="#fff"
        d="M37.579 29.443c-2.517 5.013-7.063 10.422-13.056 10.428-7.765.008-15.69-9.043-14.268-16.862.2 1.824.832 3.631 1.857 5.245 5.85 9.21 18.419 8.065 25.467 1.189Z"
      />
      <path
        fill="#fff"
        d="M33.899 6.361c3.97 3.952 7.689 9.965 5.843 15.69-2.393 7.418-13.414 12.189-20.38 8.415 1.789.373 3.696.328 5.541-.153 10.53-2.74 13.33-15.096 8.996-23.952Z"
      />
      <path
        fill="#fff"
        d="M10.9 2.74c4.97-2.57 11.815-4.263 16.667-.73 6.287 4.576 7.4 16.575 1.673 22.062a12.337 12.337 0 0 0 1.568-5.34C31.467 7.83 20.628 1.338 10.9 2.741Z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M112.513 10.382c1.133-.471 2.403-.707 3.809-.707 1.387 0 2.637.236 3.75.707a8.148 8.148 0 0 1 2.842 1.974 8.696 8.696 0 0 1 1.845 3.004c.43 1.139.645 2.396.645 3.77 0 1.375-.215 2.642-.645 3.8a8.357 8.357 0 0 1-1.816 3.005c-.781.824-1.729 1.472-2.842 1.943-1.113.472-2.373.707-3.779.707-1.387 0-2.647-.235-3.78-.707a8.66 8.66 0 0 1-2.871-1.944c-.781-.844-1.386-1.846-1.816-3.004-.41-1.159-.615-2.425-.615-3.8 0-1.374.205-2.641.615-3.8.43-1.158 1.035-2.15 1.816-2.974a8.148 8.148 0 0 1 2.842-1.974Zm8.379-1.855c-1.367-.55-2.89-.825-4.57-.825-1.68 0-3.213.284-4.6.854-1.387.55-2.578 1.325-3.574 2.327-.977 1.001-1.739 2.209-2.285 3.623-.528 1.394-.791 2.936-.791 4.624 0 1.709.273 3.27.82 4.684.547 1.394 1.309 2.592 2.285 3.593a10.422 10.422 0 0 0 3.545 2.327c1.387.55 2.92.825 4.6.825 1.68 0 3.213-.275 4.6-.825 1.386-.57 2.568-1.355 3.545-2.356.996-1.002 1.757-2.2 2.285-3.594.547-1.414.82-2.965.82-4.654 0-1.708-.273-3.26-.82-4.654-.547-1.414-1.319-2.621-2.315-3.623-.976-1.001-2.158-1.777-3.545-2.326Zm-53.009-.442v1.84H52.21v-1.84h15.674ZM54.377 17.92v.002h12.334v1.944H54.377v8.335h14.121v1.974H52.21V17.92h2.168Zm24.55-9.836 8.292 19.558 8.32-19.558h2.432l-9.698 22.15h-2.109l-9.697-22.15h2.46Zm60.78 0v20.117h13.653v1.974h-15.821V8.085h2.168Zm27.907 18.969-6.533-18.97h-2.403l7.881 22.15h2.11l6.24-18.026 6.241 18.027h2.109l7.852-22.15h-2.403l-6.533 18.969-6.533-18.97h-1.495l-6.533 18.97Zm35.265-9.133h-2.168v12.255H217v-1.974h-14.121v-8.335h12.334v-1.945h-12.334Zm13.506-9.836v1.84h-15.674v-1.84h15.674Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default EvolweIcon;
