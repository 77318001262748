import React from "react";

const RecruitButtonArrow = () => {
  return (
    <svg
      width="30"
      height="29"
      viewBox="0 0 30 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.66107 14.2674C5.66107 9.09202 9.87413 4.87896 15.0495 4.87896C20.2249 4.87896 24.4379 9.09202 24.4379 14.2674C24.4379 19.4428 20.2249 23.6558 15.0495 23.6558C9.87413 23.6558 5.66107 19.4428 5.66107 14.2674ZM3.31396 14.2674C3.31396 20.7454 8.57149 26.0029 15.0495 26.0029C21.5275 26.0029 26.785 20.7454 26.785 14.2674C26.785 7.78937 21.5275 2.53186 15.0495 2.53186C8.57148 2.53186 3.31396 7.78938 3.31396 14.2674ZM18.5702 14.2674L13.8759 18.9616L13.8759 9.57318L18.5702 14.2674Z"
        fill="black"
      />
    </svg>
  );
};

export default RecruitButtonArrow;
