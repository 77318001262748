import React from "react";
// import IntroVid from "../assets/img/IntroVideo.mp4"
import qbaLogo from "../assets/img/qbaLogo.png";

const IntroVideo = () => {
  return (
    <div className="relative pt-16 md:pt-20">
      <video autoPlay muted loop width={"100%"} preload="none">
        <source
          src="https://zone9.s3.ap-southeast-1.amazonaws.com/introduce-1.mp4"
          type="video/mp4"
        />
      </video>
      <div className="absolute bottom-[10%] screen400px:bottom-[16%] screen500px:bottom-[18%] sm:bottom-[20%] w-full flex flex-row justify-center items-center">
        <div>
          <div className="flex justify-center">
            <div className="w-[120px] screen600px:w-[240px] lg:w-[400px] xl:w-[578px]">
              <img src={qbaLogo} alt="qbaLogo" className="w-full"></img>
            </div>
          </div>
          <div className="flex justify-center pt-2 sm:pt-5">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://zone9survival.com/"
            >
              <button className="intro-button flex justify-center items-center gap-1 screen600px:gap-2 sm:gap-5 py-[10px] px-[10px] screen600px:py-3 screen600px:px-[40px] border border-white rounded-xl">
                <div className="font-roboto text-white text-xs md:text-xl lg:text-2xl">
                  GET TO KNOW MORE
                </div>
                <div>
                  <svg
                    width="14"
                    height="15"
                    viewBox="0 0 14 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.39848 0.392065L13.5079 6.44149C13.8828 6.81271 13.8828 7.41458 13.5079 7.78581L7.39848 13.8352C7.02358 14.2065 6.41574 14.2065 6.04084 13.8352C5.66593 13.464 5.66593 12.8621 6.04084 12.4909L10.5114 8.06422H0.289062V6.16307H10.5114L6.04084 1.73638C5.66593 1.36516 5.66593 0.763288 6.04084 0.392065C6.41574 0.0208417 7.02358 0.0208417 7.39848 0.392065Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroVideo;
