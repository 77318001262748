import React from "react";
import styles from "../AboutUsDescriptionItem.module.scss";
const AboutUsDescriptionItem = ({ description }) => {
  return (
    <a
      href={description.href}
      target="_blank"
      rel="noreferrer"
      className={`flex flex-col items-center gap-10 md:basis-5/12 lg:basis-3/12 border rounded-2xl border-black dark:border-white hover:bg-emerald hover:border-0 hover:text-white p-12 ${styles.title}`}
    >
      <div className="flex justify-center items-center h-40 w-36">
        {description.img}
      </div>
      <div className="hd-style text-2xl">{description.title}</div>
      <div className="p-style text-xs text-center dark:text-white">
        {description.description}
      </div>
    </a>
  );
};
export default AboutUsDescriptionItem;
