import React from "react";

const VeriChains = () => {
  return (
    <svg
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 144.16 53.5"
      fill="white"
    >
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <g>
            <path
              className="cls-1"
              d="M105.37,25.15c.94-1.07,1.98-1.39,3.19-1.32,1.48,.09,2.46,.88,2.54,2.37,.12,2.24,.08,4.49,.11,6.74,0,.75-.56,.54-.98,.58-.49,.05-.74-.11-.73-.67,.02-1.9,.01-3.8,0-5.7,0-1.37-.46-1.83-1.74-1.83-1.55,0-2.4,.68-2.4,1.92,0,1.82-.02,3.64,.01,5.46,.01,.61-.15,.86-.81,.84-.58-.02-.87-.1-.86-.79,.03-3.99,.03-7.97,0-11.96,0-.72,.34-.75,.89-.76,.6-.02,.79,.2,.78,.79-.03,1.38-.01,2.76-.01,4.34Z"
            />
            <path
              className="cls-1"
              d="M119.08,27.85c.39-1.84-.15-2.58-1.86-2.52-.76,.03-1.54,.18-2.27,.4-.51,.15-.72,.04-.93-.4-.22-.46-.15-.73,.32-.94,1.53-.69,3.12-.75,4.7-.33,1.11,.3,1.68,1.19,1.71,2.28,.06,2.35,.02,4.7,.02,7.06-1.28,.31-1.44,.22-1.69-1.05-.92,1.34-2.24,1.32-3.57,1.19-1.5-.14-2.47-1.34-2.42-2.97,.05-1.63,.92-2.59,2.48-2.68,.59-.04,1.18-.03,1.77-.04,.58,0,1.17,0,1.73,0Zm.05,1.35c-1.09,0-2.13-.02-3.16,0-.76,.02-1.15,.46-1.2,1.22-.08,1.14,.36,1.62,1.52,1.63,2.42,.02,3.13-.66,2.85-2.86Z"
            />
            <path
              className="cls-1"
              d="M74.84,29.45c0,.4-.09,.8,.02,1.13,.14,.43,.36,.92,.7,1.19,.78,.61,2.86,.42,3.51-.33,.48-.55,.82-.32,1.2,.02,.47,.42-.03,.65-.22,.91-1.05,1.48-3.39,1.42-4.8,.97-1.37-.44-2.08-1.67-2.1-3.43,0-1.02-.03-2.03,.03-3.05,.11-1.78,1.35-2.95,3.12-3.03,.43-.02,.86-.02,1.28,0,1.46,.05,2.68,1.02,2.98,2.45,.14,.67,.12,1.38,.16,2.07,.05,.71-.25,1.02-.99,1-1.55-.03-3.1,0-4.65,0-.05,0-.1,.03-.24,.08Zm.03-1.49h4.04c.23-1.11,.01-1.96-.58-2.37-.54-.38-2.2-.4-2.77,0-.84,.58-.75,1.45-.69,2.38Z"
            />
            <path
              className="cls-1"
              d="M136.85,30.88c.74-.08,1.44-.36,1.87,.59,.12,.27,.57,.51,.9,.56,.57,.09,1.18,.07,1.76,.01,.7-.07,1.12-.49,1.15-1.22,.03-.72-.31-1.18-1.02-1.3-.76-.13-1.54-.2-2.3-.34-1.68-.31-2.38-1.2-2.27-2.83,.1-1.55,.95-2.4,2.6-2.47,.85-.04,1.72-.02,2.54,.17,1.29,.3,1.92,1.32,1.81,2.63-1.08,.31-1.24,.2-1.78-.64-.2-.32-.63-.58-1-.67-.45-.11-.96-.07-1.44-.01-.61,.07-1.05,.35-1.09,1.06-.04,.71,.28,1.14,.94,1.28,.76,.16,1.53,.23,2.29,.38,1.88,.39,2.56,1.38,2.29,3.29-.17,1.18-1.03,2.02-2.35,2.14-.82,.08-1.66,.08-2.48,0-1.45-.14-2.32-1.09-2.43-2.63Z"
            />
            <path
              className="cls-1"
              d="M129.21,24.95c.87-.95,1.97-1.22,3.2-1.11,1.27,.11,2.13,.77,2.32,2.04,.15,1,.14,2.02,.15,3.03,.02,1.31-.02,2.62,.02,3.93,.02,.72-.41,.66-.88,.67-.51,.02-.79-.07-.78-.69,.03-1.79,.03-3.58,0-5.38-.01-.79,.05-1.66-.92-2.03-.9-.35-2.4-.01-2.97,.71-.18,.22-.24,.58-.24,.88-.02,1.85-.03,3.69,0,5.54,0,.62-.05,.99-.84,.99-.75,0-.89-.3-.88-.95,.03-2.59,.01-5.19,0-7.78,0-.5-.05-.95,.72-.94,.66,.01,1.12,.09,1,.9,0,.04,.04,.08,.1,.18Z"
            />
            <path
              className="cls-1"
              d="M100.16,26.68c-.63-1.3-1.89-1.76-3.22-1.17-.65,.28-.89,.84-.9,1.49-.03,1.15-.03,2.3,0,3.45,.03,1.01,.62,1.58,1.62,1.61,1.02,.04,2.03,.09,2.77-.88,.38-.49,.73,.04,.99,.27,.13,.12,.14,.55,.02,.71-.62,.86-1.44,1.42-2.56,1.41-.51,0-1.02,.02-1.52-.01-1.61-.11-2.79-1.23-2.97-2.85-.07-.66-.08-1.33-.12-2.01,.04-.69,.03-1.39,.13-2.07,.18-1.31,.89-2.3,2.2-2.57,.94-.19,1.98-.2,2.93-.05,1.18,.19,1.9,1.05,2.16,2.22"
            />
            <path
              className="cls-1"
              d="M67.84,31.19c.62-2.06,1.24-4.13,1.86-6.19,.37-1.23,.49-1.29,1.91-.98-.06,.22-.1,.45-.17,.67-.88,2.66-1.76,5.32-2.63,7.98-.17,.52-.35,.88-1.03,.88-.71,0-.89-.37-1.07-.92-.85-2.59-1.72-5.16-2.58-7.74-.09-.27-.15-.55-.23-.82,1.25-.41,1.54-.27,1.87,.83,.63,2.09,1.25,4.18,1.88,6.27,.06,0,.12,.02,.18,.03Z"
            />
            <path
              className="cls-1"
              d="M84.37,25.11c1.13-1.23,2.35-1.42,3.64-1.21,.25,.04,.68,.48,.65,.54-.22,.42-.15,1.15-.99,1-.49-.09-1.01-.13-1.51-.08-1.23,.14-1.78,.79-1.78,2,0,1.77-.02,3.53,.01,5.3,.01,.67-.21,.87-.88,.87-.7,0-.86-.26-.85-.9,.03-2.54,0-5.09,.02-7.63,0-.43-.3-1.08,.58-1.13,.75-.05,1.29,.06,1.09,1.23Z"
            />
            <path
              className="cls-1"
              d="M92.14,28.65c0,1.39-.02,2.78,.01,4.17,.01,.61-.28,.72-.79,.7-.46-.02-.93,.06-.93-.64,.01-2.78,.01-5.56,0-8.34,0-.78,.53-.62,1-.65,.51-.03,.72,.14,.71,.68-.03,1.36,0,2.73,0,4.09Z"
            />
            <path
              className="cls-1"
              d="M124.95,28.76c0,1.36-.02,2.73,.01,4.09,.02,.63-.32,.68-.8,.67-.48,0-.89,.04-.88-.67,.03-2.78,.03-5.56,0-8.33,0-.67,.39-.63,.84-.63,.44,0,.86-.05,.84,.63-.03,1.42,0,2.83,0,4.25Z"
            />
            <rect
              className="cls-1"
              x="90.42"
              y="20.02"
              width="1.75"
              height="1.75"
              rx=".87"
              ry=".87"
            />
            <rect
              className="cls-1"
              x="123.22"
              y="20.02"
              width="1.75"
              height="1.75"
              rx=".87"
              ry=".87"
            />
          </g>
          <g>
            <path
              className="cls-1"
              d="M27.16,33.29h-1.43v-.85c0-3.64,0-7.29,0-10.93,0-.49-.1-.75-.61-.93-7.37-2.73-14.73-5.49-22.09-8.24-.17-.06-.35-.1-.6-.17,0,.32,0,.58,0,.84,0,8.41,0,16.82-.02,25.23,0,.6,.17,.88,.72,1.12,3.75,1.63,7.48,3.32,11.23,4.95,.62,.27,.93,.57,.93,1.27,0,.62,.19,1.25,.32,2-1.37-.59-2.65-1.13-3.91-1.67-3.66-1.57-7.32-3.15-10.99-4.7C.2,40.99,0,40.74,0,40.16,.02,30.33,.02,20.5,0,10.67c0-.57,.17-.83,.71-1.04C9.01,6.46,17.29,3.27,25.59,.11c.36-.14,.85-.15,1.2-.02,8.29,3.16,16.58,6.36,24.87,9.52,.58,.22,.76,.49,.75,1.09-.02,9.8-.01,19.61-.01,29.41,0,.44,.02,.79-.52,1.02-4.9,2.07-9.79,4.18-14.68,6.28-.1,.04-.2,.06-.38,.1,.12-.89,.2-1.76,.38-2.6,.04-.21,.4-.39,.65-.51,3.76-1.68,7.53-3.34,11.29-5.01,.27-.12,.53-.24,.79-.36V12.33c-.97,.36-1.86,.69-2.76,1.03-6.42,2.39-12.83,4.78-19.25,7.14-.59,.22-.8,.49-.8,1.14,.04,3.56,.02,7.12,.02,10.69v.96ZM3.81,11.02c.3,.13,.45,.19,.6,.25,7.17,2.67,14.33,5.34,21.5,7.98,.35,.13,.84,.07,1.2-.06,5.57-2.04,11.13-4.11,16.7-6.18,1.71-.63,3.41-1.28,5.22-1.96-.25-.12-.36-.18-.49-.23-7.2-2.79-14.41-5.59-21.62-8.35-.33-.12-.79-.08-1.13,.05-4.2,1.6-8.38,3.24-12.57,4.86-3.09,1.2-6.18,2.39-9.42,3.65Z"
            />
            <path
              className="cls-1"
              d="M34.15,45.24c0-1.88-.43-2.48-2.17-3.05-.45-4.36-2.29-6.56-5.57-6.62-1.38-.03-2.62,.43-3.69,1.33-1.66,1.4-2.13,3.26-2,5.3-1.69,.46-2.22,1.14-2.22,2.82,0,1.9,0,3.8,0,5.7,0,1.89,.85,2.75,2.75,2.76,3.4,.01,6.8,.01,10.19,0,1.84,0,2.71-.89,2.72-2.71,.01-1.85,0-3.69,0-5.54Zm-4.42-2.83h-6.79c-.23-1.75,.16-3.19,1.71-4.06,1.18-.66,2.42-.62,3.56,.15,1.39,.94,1.62,2.33,1.51,3.91Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default VeriChains;
