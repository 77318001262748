import React from "react";
import ApprovalIcon from "./icons/ApprovalIcon";
import RecruitButtonArrow from "./icons/RecruitButtonArrow";

const Recruit = () => {
  return (
    <div className="bg-[#191919] gap-5 flex flex-col rounded-[17.6033px] h-full p-10 justify-between w-full sm:w-[37%] lg:w-[23%]">
      <div>
        <span className="hd-style text-2xl font-bold">Join us </span>
        <span className="p-style text-white text-2xl font-bold">
          to become beyond 'awesome'.
        </span>
      </div>
      <div className="flex gap-[10px] items-center">
        <div>
          <ApprovalIcon />
        </div>
        <div className="p-style text-white">Competitive Salary</div>
      </div>
      <div className="flex gap-[10px] items-center">
        <div>
          <ApprovalIcon />
        </div>
        <div className="p-style text-white">100% Remote Available</div>
      </div>
      <div className="flex gap-[10px] items-center">
        <div>
          <ApprovalIcon />
        </div>
        <div className="p-style text-white">Hiring Globally</div>
      </div>
      <button className="flex w-full justify-center items-center gap-[10px] p-style text-xs bg-emerald py-[13px] px-[18px] rounded-[4.25364px] active:shadow-[inset_0_4px_4px_rgba(0,0,0,0.25)] hover:rounded-[40px] recruit-button">
        View open Position
        <span>
          <RecruitButtonArrow />
        </span>
      </button>
    </div>
  );
};

export default Recruit;
