import React from "react";
import styles from "../AboutUsDescriptionItem.module.scss";
const AboutUsVisionItems = ({ vision }) => {
  return (
    <a
      href="/"
      className={`flex flex-col justify-around items-center gap-10 basis-[30%] border rounded-2xl border-black dark:border-white hover:bg-emerald hover:border-0 hover:text-white p-12 ${styles.title}`}
    >
      <div className="flex justify-center items-center h-40 w-36">
        {vision.img}
      </div>
      <div className="hd-style text-2xl text-center">{vision.title}</div>
      <div className="p-style text-xs text-center dark:text-white">
        {vision.description}
      </div>
    </a>
  );
};
export default AboutUsVisionItems;