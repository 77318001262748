import React from "react";

const Cube = () => {
  return (
    <svg
      width="124"
      height="124"
      viewBox="0 0 124 124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2727 16.9091C11.2727 13.8091 13.8091 11.2727 16.9091 11.2727H28.1818V0H16.9091C7.55273 0 0 7.55273 0 16.9091V28.1818H11.2727V16.9091Z"
        fill="black"
      />
      <path
        d="M11.2727 107.091V95.8182H0V107.091C0 116.447 7.55273 124 16.9091 124H28.1818V112.727H16.9091C13.8091 112.727 11.2727 110.191 11.2727 107.091Z"
        fill="black"
      />
      <path
        d="M107.091 0H95.8182V11.2727H107.091C110.191 11.2727 112.727 13.8091 112.727 16.9091V28.1818H124V16.9091C124 7.55273 116.447 0 107.091 0Z"
        fill="black"
      />
      <path
        d="M112.727 107.091C112.727 110.191 110.191 112.727 107.091 112.727H95.8182V124H107.091C116.447 124 124 116.447 124 107.091V95.8182H112.727V107.091Z"
        fill="black"
      />
      <path
        d="M101.455 78.1764V45.8236C101.455 41.7655 99.3127 38.0455 95.8182 36.0727L67.6364 19.84C65.8891 18.8255 63.9727 18.3182 62 18.3182C60.0273 18.3182 58.1109 18.8255 56.3636 19.84L28.1818 36.0164C24.6873 38.0455 22.5455 41.7655 22.5455 45.8236V78.1764C22.5455 82.2345 24.6873 85.9545 28.1818 87.9273L56.3636 104.16C58.1109 105.175 60.0273 105.682 62 105.682C63.9727 105.682 65.8891 105.175 67.6364 104.16L95.8182 87.9273C99.3127 85.9545 101.455 82.2345 101.455 78.1764ZM56.3636 91.14L33.8182 78.1764V52.08L56.3636 65.2127V91.14ZM62 55.4618L39.68 42.4418L62 29.5909L84.32 42.4418L62 55.4618ZM90.1818 78.1764L67.6364 91.14V65.2127L90.1818 52.08V78.1764Z"
        fill="black"
      />
    </svg>
  );
};

export default Cube;
