import React from "react";

import Logo from "../icons/Logo";

const Footer = () => {
  return (
    <div className="bg-emerald dark:bg-lightgray">
      <div className="mx-auto padding-layout py-3 md:py-6 lg:py-[40px] xl:py-[60px]">
        <div className="flex flex-col items-center justify-between gap-5 md:gap-10">
          <div className="text-center p-style text-white text-[25px] md:text-[36px] font-bold">STAY CONNECTED</div>
          <a href="https://qba.studio">
            <Logo />
          </a>
          <div className="text-center p-style text-white leading-8 text-[10px] md:text-[12px] lg:text-[14px]">
            <div>
              @2023 QBA DIGITAL TECHNOLOGY, LTE. ALL RIGHT RESERVED.
              <br />
              All trademark referenced herein are the properties of their
              respective owners.
              <br /> Address: 629 Kim Ma Street, Ngoc Khanh Ward, Ba Dinh
              District, Hanoi, Vietnam <br />
              Tax ID: 0110021764
            </div>
          </div>
          {/* <div className="flex gap-[10px] p-style text-white">
            <div className="">PRIVACY POLICY</div>
            <div className="">TERMS OF SERVICE</div>
            <div className="">COOKIES</div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
