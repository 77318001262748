import React from "react";

const Jigsaw = () => {
  return (
    <svg
      width="124"
      height="124"
      viewBox="0 0 124 124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M54.0513 12.7179C55.8318 12.7179 57.2308 14.1169 57.2308 15.8974V28.6154H95.3846V66.7692H108.103C109.883 66.7692 111.282 68.1682 111.282 69.9487C111.282 71.7292 109.883 73.1282 108.103 73.1282H95.3846V111.282H81.9036C77.5795 100.154 66.7056 92.2051 54.0513 92.2051C41.3969 92.2051 30.5231 100.154 26.199 111.282H12.7179V97.801C23.8462 93.4769 31.7949 82.6031 31.7949 69.9487C31.7949 57.2944 23.9097 46.4205 12.7815 42.0964L12.7179 28.6154H50.8718V15.8974C50.8718 14.1169 52.2708 12.7179 54.0513 12.7179ZM54.0513 0C45.2759 0 38.1538 7.12205 38.1538 15.8974H12.7179C5.72308 15.8974 0.0635897 21.6205 0.0635897 28.6154V52.7795H1.90769C11.3826 52.7795 19.0769 60.4738 19.0769 69.9487C19.0769 79.4236 11.3826 87.118 1.90769 87.118H0V111.282C0 118.277 5.72308 124 12.7179 124H36.8821V122.092C36.8821 112.617 44.5764 104.923 54.0513 104.923C63.5262 104.923 71.2205 112.617 71.2205 122.092V124H95.3846C102.379 124 108.103 118.277 108.103 111.282V85.8462C116.878 85.8462 124 78.7241 124 69.9487C124 61.1733 116.878 54.0513 108.103 54.0513V28.6154C108.103 21.6205 102.379 15.8974 95.3846 15.8974H69.9487C69.9487 7.12205 62.8267 0 54.0513 0Z"
        fill="black"
      />
    </svg>
  );
};

export default Jigsaw;
