import React from "react";

import TeamItems from "./TeamItems";
import LinkedIn2 from "./icons/LinkedIn2";
import ArtStationIcon from "./icons/ArtStationIcon";

const Team = () => {
  const teamItems = [
    {
      id: 1,
      img: require("../assets/img/team1.png"),
      name: "Anh Nguyen",
      position: "CHIEF EXECUTIVE OFFICER",
      icon: <LinkedIn2 />,
      link: "https://www.linkedin.com/in/anh-nguyen-76a307202/",
    },
    {
      id: 2,
      img: require("../assets/img/team2.png"),
      name: "Nguyen Bui",
      position: "Growth Lead/Marketing Advisor",
      icon: <LinkedIn2 />,
      link: "https://www.linkedin.com/in/vnbui/",
    },
    {
      id: 3,
      img: require("../assets/img/team3.png"),
      name: "Le linh",
      position: "Chief Business Officer",
      icon: <LinkedIn2 />,
      link: "https://www.linkedin.com/in/linhmatalabs/",
    },
    {
      id: 4,
      img: require("../assets/img/team4.png"),
      name: "Quang Nguyen",
      position: "3D ARTIST",
      icon: <ArtStationIcon />,
      link: "https://www.artstation.com/duyquangnguyn",
    },
    {
      id: 5,
      img: require("../assets/img/team5.png"),
      name: "Bang Bui",
      position: "STORY WRITER",
      icon: <LinkedIn2 />,
      link: "https://www.linkedin.com/in/bang-bui-khac-8a5b98241/",
    },
  ];

  return (
    <div className="dark:bg-black padding-layout">
      <div id="team" className="hd-style text-3xl sm:text-5xl text-center">
        MEET OUR TEAM
      </div>
      {/* <div className="p-style text-base sm:text-2xl text-center py-10 dark:text-white">
        Get to know the Bears. Bear for changes, Bear for innovations and Bear
        for opportunities.
      </div> */}
      <div className="flex flex-wrap justify-center items-center gap-10 py-12">
        {teamItems.map((teamItem) => (
          <TeamItems key={teamItem.id} team={teamItem} />
        ))}
      </div>
    </div>
  );
};

export default Team;
