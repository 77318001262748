import React from "react";
import Slider from "react-slick";
const TeamCarousel = () => {
  const teamCarouselItems = [
    { id: 1, img: require("../assets/img/teamphoto1.png") },
    { id: 2, img: require("../assets/img/teamphoto2.png") },
    { id: 3, img: require("../assets/img/teamphoto3.png") },
    { id: 4, img: require("../assets/img/teamphoto4.png") },
  ];
  var teamCarousel = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="bg-black">
      <div className="padding-layout">
        <div className="screen600px:px-32">
          <div className="hd-style text-white text-sm screen600px:text-lg sm:text-3xl text-center pb-10">
            WE ARE THE DEVELOPERS BEHIND ZONE 9 SURVIVAL
          </div>
          <Slider {...teamCarousel}>
            {teamCarouselItems.map((teamCarouselItem) => (
              <div key={teamCarouselItem.id}>
                <img alt="Team" src={teamCarouselItem.img} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default TeamCarousel;
