import React from "react";

const LinkedIn2 = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.8657 0.208984H2.62038C1.17154 0.208984 0 1.3528 0 2.76697V33.137C0 34.5512 1.17154 35.7019 2.62038 35.7019H32.8657C34.3145 35.7019 35.493 34.5512 35.493 33.144V2.76697C35.493 1.3528 34.3145 0.208984 32.8657 0.208984ZM10.53 30.4543H5.26155V13.5119H10.53V30.4543ZM7.8958 11.2035C6.20434 11.2035 4.83869 9.83784 4.83869 8.15331C4.83869 6.46878 6.20434 5.10313 7.8958 5.10313C9.58033 5.10313 10.946 6.46878 10.946 8.15331C10.946 9.8309 9.58033 11.2035 7.8958 11.2035ZM30.2453 30.4543H24.9837V22.2188C24.9837 20.257 24.9491 17.7267 22.2455 17.7267C19.5073 17.7267 19.0913 19.8688 19.0913 22.0801V30.4543H13.8367V13.5119H18.8834V15.8273H18.9527C19.6528 14.4963 21.372 13.089 23.93 13.089C29.2609 13.089 30.2453 16.5967 30.2453 21.1581V30.4543Z"
        fill="#0B110D"
      />
    </svg>
  );
};

export default LinkedIn2;
