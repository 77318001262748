import React from "react";

const BlackArrow = () => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.39848 0.392065L13.5079 6.44149C13.8828 6.81271 13.8828 7.41458 13.5079 7.78581L7.39848 13.8352C7.02358 14.2065 6.41574 14.2065 6.04084 13.8352C5.66593 13.464 5.66593 12.8621 6.04084 12.4909L10.5114 8.06422H0.289062V6.16307H10.5114L6.04084 1.73638C5.66593 1.36516 5.66593 0.763288 6.04084 0.392065C6.41574 0.0208417 7.02358 0.0208417 7.39848 0.392065Z"
        fill="black"
      />
    </svg>
  );
};

export default BlackArrow;
