import React from "react";

const SmNavMenu = () => {
  return (
    <svg className="sm-nav-menu"
      width="55"
      height="39"
      viewBox="0 0 55 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 39H55V32.5H0V39ZM0 22.75H55V16.25H0V22.75ZM0 0V6.5H55V0H0Z"
        fill="white"
      />
    </svg>
  );
};

export default SmNavMenu;
