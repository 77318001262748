import React, { useEffect } from "react";
import { useLocalStorageState } from "ahooks";

import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ children }) => {
  const [theme, setTheme] = useLocalStorageState("theme", {
    defaultValue: "light",
  });

  const handleClick = () => {
    setTheme(theme === "dark" ? "light" : "dark");
  };

  useEffect(() => {
    if (theme === "dark") {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [theme]);

  return (
    <div>
      <Header theme={theme} onClick={handleClick} />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
