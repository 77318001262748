import React from "react";

const Service = () => {
  return (
    <div className="dark:bg-black padding-layout">
      <div className="flex flex-col gap-5">
        <div id="service" className="hd-style text-3xl sm:text-5xl text-center">Services</div>
        <div className="p-style text-base sm:text-2xl dark:text-white">
          1. Tell a compelling story and are fun for everyone to play - we hope
          to reach the masses, specifically gamers that enjoy playing with
          others, whether in Dungeon style PvE or MOBA PvP add period.
        </div>
        <div className="p-style text-base sm:text-2xl dark:text-white">
          2. Allow you to have full ownership over all of your in-game assets
          (characters, items, in-game currency, equipment, land, etc.), and
          provide you with opportunities to make profit or earn real-life money
          by simply playing the game.
        </div>
        <div className="p-style text-base sm:text-2xl dark:text-white">
          To accomplish our main goal and vision, there are various phases that
          we must complete first. Each of these phases are described in our
          roadmap add period
        </div>
      </div>
      <div className="flex justify-center p-5">
        <button className="shadow-[0_4px_4px_rgba(0,0,0,25%)] active:shadow-[inset_0_4px_4px_rgba(0,0,0,0.25)] rounded-[74px] bg-emerald p-style text-white text-2xl py-[10px] px-[50px]">contact us</button>
      </div>
    </div>
  );
};

export default Service;
