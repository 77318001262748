import React from "react";

const ArtStationIcon = () => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.0236816 13.7656C2.65489 13.7666 5.28611 13.7679 7.91732 13.7686C9.30709 13.7691 10.6966 13.7691 12.0864 13.7679C12.1921 13.7679 12.2756 13.7706 12.3411 13.8853C13.0559 15.135 13.7773 16.3809 14.4965 17.6278C14.5147 17.6595 14.5271 17.6946 14.5538 17.7535C14.4618 17.7535 14.3935 17.7535 14.3252 17.7535C10.7417 17.7535 7.15825 17.7515 3.57476 17.7549C2.73093 17.7557 2.11369 17.3932 1.69689 16.6618C1.16865 15.735 0.629447 14.8144 0.09423 13.8915C0.0757828 13.8596 0.0478631 13.8334 0.0244302 13.8045C0.0236824 13.7916 0.0236816 13.7786 0.0236816 13.7656Z"
          fill="black"
        />
        <path
          d="M17.8076 17.723C14.4679 11.9358 11.1372 6.16388 7.79126 0.365733C7.8825 0.361246 7.94332 0.355513 8.00415 0.355263C9.05339 0.354765 10.1026 0.357258 11.1516 0.354017C11.9728 0.351524 12.5803 0.709 12.9879 1.41847C15.1661 5.20936 17.3479 8.99851 19.5219 12.7921C19.8784 13.4144 19.9195 14.0677 19.5603 14.7057C19.0084 15.6861 18.4388 16.6566 17.8761 17.631C17.8604 17.658 17.8392 17.6809 17.8076 17.723Z"
          fill="black"
        />
        <path
          d="M6.1442 3.1626C7.64266 5.75891 9.12616 8.3293 10.6234 10.9231C7.6297 10.9231 4.66444 10.9231 1.66528 10.9231C3.1595 8.33428 4.64226 5.76514 6.1442 3.1626Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

export default ArtStationIcon;
