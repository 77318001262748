import React from "react";

const GamesCarousel = ({ gameCarousel }) => {
  return (
    <div className="relative carousel-moveup m-[10px]">
      <img className="" alt={gameCarousel.alt} src={gameCarousel.img} />
      <div className="absolute moveup top-[70%] p-4 w-full h-full">
        <div className="flex carousel-style text-white font-normal text-xs sm:text-base lg:text-xs">
          <div className="bg-darkorange py-[1px] px-5">
            {gameCarousel.title}
          </div>
        </div>
        <div className="carousel-style py-1 text-white font-extrabold text-sm screen500px:text-lg screen600px:text-3xl sm:text-2xl md:text-xl lg:text-sm xl:text-2xl">
          {gameCarousel.content}
        </div>
        <div>
          <div className="carousel-style carousel-hidden-text text-white font-medium hidden text-[10px] screen500px:text-base screen600px:text-2xl sm:text-xl md:text-lg lg:text-xs xl:text-lg">
            {gameCarousel.subcontent}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GamesCarousel;
