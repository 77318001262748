import React from "react";

const ApprovalIcon = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.9734" cy="13.0553" r="12.4734" fill="#A3C6C2" />
      <rect
        x="6.16199"
        y="11.8188"
        width="8.65498"
        height="1.52735"
        transform="rotate(45 6.16199 11.8188)"
        fill="white"
      />
      <rect
        x="20.9218"
        y="9.29895"
        width="13.7461"
        height="1.52735"
        transform="rotate(135 20.9218 9.29895)"
        fill="white"
      />
    </svg>
  );
};

export default ApprovalIcon;
