import React from "react";
import Layout from "../components/layout/Layout";
import IntroVideo from "../components/IntroVideo";
import AboutUsMain from "../components/AboutUsMain";
import Updates from "../components/Updates";
import Games from "../components/Games";
import Partner from "../components/Partner";
import Recruit from "../components/Recruit";
import Products from "../components/Products";
const Home = () => {
  return (
    <Layout>
      <IntroVideo />
      <AboutUsMain />
      <Products />
      <Updates />
      <Games />
      <div className="dark:bg-black padding-layout">
        <div
          id="career"
          className="flex flex-col sm:flex-wrap sm:flex-row px-[10px] gap-[20px] sm:h-[450px] justify-around mb-[48px] pt-[26px]"
        >
          <Partner />
          <Recruit />
        </div>
      </div>
    </Layout>
  );
};

export default Home;
