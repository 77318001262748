import React from "react";
import Slider from "react-slick";

import AboutUsCarousel from "./AboutUsCarousel";
import AboutUsDescriptionItem from "./AboutUsDescriptionItem";
import BrainStorm from "./icons/BrainStorm";
import LightBulb from "./icons/LightBulb";
import Tools from "./icons/Tools";
import Writing from "./icons/Writing";

const descriptionItems = [
  {
    id: 1,
    title: "Vision",
    description:
      "To make the most of available resources to generate high-quality goods that are always on the leading edge",
    img: <LightBulb />,
    href: "/about-us/#vision",
  },
  {
    id: 2,
    title: "Mission",
    description:
      "We aim to create a technological firm with long-term growth at a new high level.",
    img: <Writing />,
    href: "/about-us/#mission",
  },
  {
    id: 3,
    title: "Team",
    description:
      "An experienced team that share the same goal and passion of achieving new heights of technology application",
    img: <Tools />,
    href: "/about-us/#team",
  },
  {
    id: 4,
    title: "Service",
    description:
      "Services provided with professions: Software Development, Art Production, Game Development, Animation",
    img: <BrainStorm />,
    href: "/about-us/#service",
  },
];

var aboutus = {
  dots: true,
  infinite: true,
  speed: 500,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
};
const AboutUsMain = () => {
  return (
    <div className="dark:bg-black padding-layout">
      <div className="hd-style text-center text-2xl screen600px:text-5xl pb-10 px-[10px] pt-[48px]">
        AboutUs
      </div>
      <div className="p-style text-sm screen600px:text-2xl text-center pb-10 dark:text-white px-[10px]">
        We are QBA DIGITECH CO., LTD, we are leading innovators of the digital
        era. With our top-tier expertises in Research & Development, Marketing
        and Technology, we provide a wide variety of services such as producing
        Digital Publication, TVC/KV, Mobile, Music Video.
      </div>
      <div className="hidden md:flex flex-wrap lg:flex-nowrap justify-center gap-10 px-24 md:px-18 lg:px-0">
        {descriptionItems.map((descriptionItem) => (
          <AboutUsDescriptionItem
            key={descriptionItem.id}
            description={descriptionItem}
          />
        ))}
      </div>
      <div className="block md:hidden h-96 screen600px:px-24">
        <Slider {...aboutus}>
          {descriptionItems.map((descriptionItem) => (
            <AboutUsCarousel
              key={descriptionItem.id}
              carouselDescription={descriptionItem}
            />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default AboutUsMain;
