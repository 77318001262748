import React from "react";
import Slider from "react-slick";

import AboutUsVisionCarousel from "./AboutUsVisionCarousel";
import AboutUsVisionItems from "./AboutUsVisionItems";
import Cube from "./icons/Cube";
import Fidget from "./icons/Fidget";
import Jigsaw from "./icons/Jigsaw";

const Vision = () => {
  const visionItems = [
    {
      id: 1,
      title: "A COMMUNITY-DRIVEN GAME",
      description:
        "To make the most of available resources to generate high-quality goods that are always on the leading edge",
      img: <Fidget />,
    },
    {
      id: 2,
      title: "A GAME THAT YOU AND WE WANT TO PLAY",
      description:
        "Offer a game that you, and we want to play. A beautifully designed Game World and Characters with a long road map that you will get to decide what's next.",
      img: <Jigsaw />,
    },
    {
      id: 3,
      title: "TECHNOLOGY KNOWLEDGE",
      description:
        "Bring blockchain technology knowledge to the masses, absorbed through the fun of playing",
      img: <Cube />,
    },
  ];

  var aboutusvision = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="dark:bg-black padding-layout">
      <div id="vision" className="hd-style text-center pb-10 text-3xl sm:text-5xl">Vision</div>
      <div>
        <div className="hidden screen1100px:flex flex-wrap lg:flex-nowrap justify-between gap-5">
          {visionItems.map((visionItem) => (
            <AboutUsVisionItems key={visionItem.id} vision={visionItem} />
          ))}
        </div>
        <div className="block screen1100px:hidden h-96 screen500px:px-24 sm:px-32">
          <Slider {...aboutusvision}>
            {visionItems.map((visionItem) => (
              <AboutUsVisionCarousel
                key={visionItem.id}
                visionCarousel={visionItem}
              />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Vision;
