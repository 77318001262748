import { Row } from 'antd';
import React, { useState } from 'react';
import Slider from 'react-slick';
import Product1 from '../assets/img/Product1.png';
import Product2 from '../assets/img/Product2.png';
import Product3 from '../assets/img/Product3.png';
import Product from './Product';

const products = [
  {
    image: Product1,
    url: 'https://docln.net/sang-tac/14516-dia-dang-so-9',
    linkTitle: 'GO TO WEBSITE',
  },
  {
    image: Product2,
    url: 'https://zone9survival.com/',
    linkTitle: 'GO TO WEBSITE',
  },
  {
    image: Product3,
    url: 'https://docsend.com/view/azyueuq7ukn94etv',
    linkTitle: 'GO TO DOCS',
  },
];

const Products = () => {
  const [isHover, setIsHover] = useState(0);
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrow: false,
    centerMode: true,
    centerPadding: '80px',
  };
  return (
    <div className="dark:bg-black padding-layout">
      <div className="hd-style text-center text-2xl screen600px:text-5xl pb-10 px-[10px] pt-[92px] md:pt-0">
        OUR PRODUCTS
      </div>
      <Row className="hidden md:flex justify-center" gutter={[40, 40]}>
        {products.map((product, index) => (
          <Product product={product} key={index} />
        ))}
      </Row>
      <div className="block md:hidden">
        <Slider {...settings}>
          <div className="relative">
            <div
              onMouseEnter={() => {
                setIsHover(1);
              }}
              className="p-[0px]"
            >
              <img loading="lazy" width={'100%'} alt="" src={Product1} />
            </div>
            {isHover === 1 && (
              <div className="absolute top-0 left-[0.1%] bottom-[2%] right-[2.5%] z-9">
                <div
                  onMouseLeave={() => {
                    setIsHover(0);
                  }}
                  className="flex justify-center items-center h-[100%] bg-[#05050580] rounded-[4%]"
                >
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://themonopolist.io/"
                  >
                    <button className="text-white text-[12px] sm:text-[16px] font-[600] bg-[#4D6255] rounded-[5px] hover:bg-[#A6D1B6] hover:text-black px-[7px] py-[15px]">
                      GO TO WEBSITE
                    </button>
                  </a>
                </div>
              </div>
            )}
          </div>
          <div className="relative">
            <div
              onMouseEnter={() => {
                setIsHover(2);
              }}
              className="p-[0px] bg-white"
            >
              <img loading="lazy" width={'100%'} alt="" src={Product2} />
            </div>
            {isHover === 2 && (
              <div className="absolute top-[1%] left-[1.5%] bottom-[3%] right-[3.5%] z-9">
                <div
                  onMouseLeave={() => {
                    setIsHover(0);
                  }}
                  className="flex justify-center items-center h-[100%] bg-[#05050580] rounded-[4%]"
                >
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://zone9survival.com/"
                  >
                    <button className="text-white text-[12px] sm:text-[16px] font-[600] bg-[#4D6255] rounded-[5px] hover:bg-[#A6D1B6] hover:text-black px-[7px] py-[15px]">
                      GO TO WEBSITE
                    </button>
                  </a>
                </div>
              </div>
            )}
          </div>
          <div className="relative">
            <div
              onMouseEnter={() => {
                setIsHover(3);
              }}
              className="p-[0px]"
            >
              <img loading="lazy" width={'100%'} alt="" src={Product3} />
            </div>
            {isHover === 3 && (
              <div className="absolute top-[1%] left-[1.5%] bottom-[3%] right-[3.5%] z-9">
                <div
                  onMouseLeave={() => {
                    setIsHover(0);
                  }}
                  className="flex justify-center items-center h-[100%] bg-[#05050580] rounded-[4%]"
                >
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://docsend.com/view/frb6u5su8ei5c9hu"
                  >
                    <button className="text-white text-[12px] sm:text-[16px] font-[600] bg-[#4D6255] rounded-[5px] hover:bg-[#A6D1B6] hover:text-black px-[7px] py-[15px]">
                      GO TO DOCS
                    </button>
                  </a>
                </div>
              </div>
            )}
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default Products;
