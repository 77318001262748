import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import GamesBanner from "../assets/img/Z9-banner.png";
import GamesBanner600 from "../assets/img/Z9-banner-600.png";
import BlackArrow from "./icons/BlackArrow";
import GamesCarousel from "./GamesCarousel";
const Games = () => {
  const gameItems = [
    {
      id: 0,
      content: "Our Team ",
      subcontent:
        "Behind this innovative and captivating game is a team of industry experts who have worked with some of the biggest game studios in the industry. The team's extensive experience in game development has been instrumental in crafting a game that is both engaging and realistic.",
      img: require("../assets/img/Z9-1.png"),
      alt: "game updates",
    },
    {
      id: 1,
      content: "Interactive NPC",
      subcontent:
        "The game's advanced smart AI system for the NPCs is a testament to the team's dedication to realism and immersion. The NPCs can remember the player's actions and interact with them accordingly, making the game feel more lifelike and immersive. Be careful if you have wronged an NPC because they might try to get revenge!",
      img: require("../assets/img/Z9-2.png"),
      alt: "game updates",
    },
    {
      id: 2,
      content: "Guild System",
      subcontent:
        "The guild system in 'Zone 9 Survival' allows players to join or create their own guilds, cooperate, and compete against other guilds in the game's world. Guild wars add an exciting element of competition to the game, allowing players to battle it out for supremacy and valuable resources.",
      img: require("../assets/img/Z9-3.png"),
      alt: "game updates",
    },
  ];
  var games = {
    dots: true,
    infinite: false,
    speed: 500,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  return (
    <div className="dark:bg-black padding-layout">
      <div id="game" className="">
        <div className="px-[10px] hidden screen600px:block">
          <img alt="Game banner" src={GamesBanner} />
        </div>
        <div className="px-[10px] block screen600px:hidden">
          <img alt="Game banner" src={GamesBanner600} />
        </div>
      </div>
      <div className="flex flex-wrap justify-between items-center gap-5 px-[10px] py-10">
        <div className="w-full md:w-7/12 text-justify">
          <div className="p-style text-base dark:text-white">
            Zone 9 Survival" is an exceptional game and is a must-play for fans
            of MMO RPGs and zombie survival games. Do you have what it take to
            survive?
            <br /> Will you dare risk your life to make it to Heaven? No rule,
            only Survival!
            <br /> Survivor! Join us at Zone 9 Survival!
          </div>
        </div>
        <button className="w-full md:w-4/12">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://zone9survival.com/"
            className="flex items-center justify-center gap-4 border border-black dark:text-white dark:border-white py-2 px-5 hover:rounded-[40px] hover:bg-emerald hover:border-0 hover:text-white button-hover"
          >
            <div className="p-style text-base">MORE ON Z9S WEBSITE</div>
            <div>
              <BlackArrow />
            </div>
          </a>
        </button>
      </div>
      <div className="hover:cursor-pointer">
        <Slider {...games}>
          {gameItems.map((gameItem) => (
            <GamesCarousel key={gameItem.id} gameCarousel={gameItem} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Games;
