import React from "react";
import DnaLawyer from "../assets/icon/DnaLawyer.png";
import Dega from "../assets/icon/Dega.png";
import GameSwift from "../assets/icon/GameSwift.png";
import Orochi from "../assets/icon/Orochi.png";
import VeriChains from "./icons/VeriChains";
import SupraIcons from "./icons/SupraIcons";
import EvolweIcon from "./icons/EvolweIcon";
import myria from "../assets/icon/myria.png";
import smartosc from "../assets/icon/smartosc.png";
const Partner = () => {
  return (
    <div className="bg-[#191919] gap-5 flex flex-col rounded-[17.6033px] h-full p-10 justify-center items-center w-full sm:w-[57%] lg:w-[73%]">
      <div className="hd-style text-3xl">OUR PARTNER</div>
      <div className="flex flex-wrap gap-10 justify-center items-center">
        <div className="w-1/5">
          <img alt="Myria" src={myria} />
        </div>
        <div className="w-1/5">
          <img alt="GameSwift" src={GameSwift} />
        </div>
        <div className="w-1/5">
          <img alt="Orochi" src={Orochi} />
        </div>
        <div className="w-1/5">
          <img alt="Dega" src={Dega} />
        </div>
        <div className="w-1/5">
          <VeriChains />
        </div>
        <div className="w-1/5">
          <img alt="DnaLawyer" src={DnaLawyer} />
        </div>
        <div className="w-1/5">
          <EvolweIcon />
        </div>
        <div className="w-1/5">
          <SupraIcons />
        </div>
        <div className="w-1/5">
          <img alt="Smartosc" src={smartosc} />
        </div>
      </div>
    </div>
  );
};

export default Partner;
