import React from "react";
import Logo from "../icons/Logo";
import CaretDown from "../icons/CaretDown";
import { Dropdown, Menu, Space } from "antd";
import Lightmode from "../icons/LightMode";
import Darkmode from "../icons/DarkMode";
import SmallLogo from "../icons/SmallLogo";
import SmNavMenu from "../icons/SmNavMenu";

const lang = (
  <Menu
    className=" header-menu"
    items={[
      {
        label: (
          <a className="nav-style text-sm md:text-xl" href="/">
            <div className="flex nav-style  justify-around items-center">
              VN
              <CaretDown />
            </div>
          </a>
        ),
        key: "0",
      },
    ]}
  />
);
const menu = (
  <Menu
    className="header-menu-2"
    items={[
      {
        label: <div className="nav-style text-sm md:text-xl">HOME</div>,
        key: "0",
      },
      {
        label: <div className="nav-style text-sm md:text-xl">GAMES</div>,
        key: "1",
      },
      {
        label: <div className="nav-style text-sm md:text-xl">CAREERS</div>,
        key: "2",
      },
      {
        label: <div className="nav-style text-sm md:text-xl">ABOUT</div>,
        key: "3",
      },
    ]}
  />
);

const Header = ({ theme, onClick }) => {
  const handleClick = () => {
    onClick();
  };

  return (
    <div className="fixed top-0 w-full z-10 bg-emerald dark:bg-lightgray">
      <div className="mx-auto padding-layout py-3 md:py-6">
        <div className="flex justify-around items-center">
          <a href="https://qba.studio/">
            <div className="hidden md:block">
              <Logo />
            </div>
            <div className="block md:hidden">
              <SmallLogo />
            </div>
          </a>
          <div className="flex ml-auto space-x-4 justify-around items-center">
            <a href="https://qba.studio/" className="hidden md:block nav-style text-xl">
              HOME
            </a>
            <a href="https://qba.studio/#game" className="hidden md:block nav-style text-xl">
              GAMES
            </a>
            <a href="https://qba.studio/#career" className="hidden md:block nav-style text-xl">
              CAREERS
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://qba.studio/about-us"
              className="hidden md:block nav-style text-xl"
            >
              ABOUT
            </a>
            <Dropdown overlay={lang} trigger={["click"]}>
              <button className="nav-style text-sm md:text-xl hover:text-white">
                <Space>
                  ENG
                  <CaretDown />
                </Space>
              </button>
            </Dropdown>
            <div className="block md:hidden">
              <Dropdown overlay={menu} trigger={["click"]}>
                <a href="/" id="sm-navmenu" onClick={(e) => e.preventDefault()}>
                  <Space>
                    <SmNavMenu />
                  </Space>
                </a>
              </Dropdown>
            </div>
            {theme === "dark" ? (
              <button className="darkmode-border" onClick={handleClick}>
                <Darkmode />
              </button>
            ) : (
              <button className="darkmode-border" onClick={handleClick}>
                <Lightmode />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
