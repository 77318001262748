import React from "react";
import Layout from "../components/layout/Layout";

import AboutUsPageIntro from "../components/AboutUsPageIntro";
import Vision from "../components/Vision";
import { Mission } from "../components/Mission";
import Team from "../components/Team";
import TeamCarousel from "../components/TeamCarousel";
import Service from "../components/Service";

const AboutUs = () => {
  return (
    <Layout>
      <AboutUsPageIntro />
      <Vision />
      <Mission />
      <Team />
      <TeamCarousel />
      <Service />
    </Layout>
  );
};

export default AboutUs;
