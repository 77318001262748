import React from "react";

const Fidget = () => {
  return (
    <svg
      width="130"
      height="124"
      viewBox="0 0 130 124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52 84.8421H90.22C91.975 82.8189 94.575 81.5789 97.5 81.5789C102.895 81.5789 107.25 85.9516 107.25 91.3684C107.25 96.7853 102.895 101.158 97.5 101.158C94.64 101.158 92.04 99.9179 90.22 97.8947H64.35C61.36 112.775 48.23 124 32.5 124C14.56 124 0 109.381 0 91.3684C0 75.5747 11.18 62.3916 26 59.3895V72.8989C18.46 75.5747 13 82.8842 13 91.3684C13 102.137 21.775 110.947 32.5 110.947C43.225 110.947 52 102.137 52 91.3684V84.8421ZM68.25 13.0526C78.975 13.0526 87.75 21.8632 87.75 32.6316H100.75C100.75 14.6189 86.19 0 68.25 0C50.31 0 35.75 14.6189 35.75 32.6316C35.75 41.9642 39.65 50.3179 45.825 56.2568L30.55 81.7095C26.13 82.6232 22.75 86.6042 22.75 91.3684C22.75 96.7853 27.105 101.158 32.5 101.158C37.895 101.158 42.25 96.7853 42.25 91.3684C42.25 90.3242 42.12 89.3453 41.795 88.4316L63.765 51.6884C55.185 49.6653 48.75 41.8989 48.75 32.6316C48.75 21.8632 57.525 13.0526 68.25 13.0526ZM97.5 71.7895C93.34 71.7895 89.505 73.0947 86.32 75.3137L66.495 42.2253C61.945 41.4421 58.5 37.4611 58.5 32.6316C58.5 27.2147 62.855 22.8421 68.25 22.8421C73.645 22.8421 78 27.2147 78 32.6316C78 33.6105 77.87 34.5242 77.61 35.4379L91.845 59.2589C93.665 58.9326 95.55 58.7368 97.5 58.7368C115.44 58.7368 130 73.3558 130 91.3684C130 109.381 115.44 124 97.5 124C85.475 124 74.945 117.408 69.355 107.684H86.71C89.83 109.773 93.535 110.947 97.5 110.947C108.225 110.947 117 102.137 117 91.3684C117 80.6 108.225 71.7895 97.5 71.7895Z"
        fill="black"
      />
    </svg>
  );
};

export default Fidget;
