import React from "react";

const Tools = () => {
  return (
    <svg
      width="108"
      height="112"
      viewBox="0 0 108 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M93.5344 2.68702C93.8851 1.87617 94.6744 1.37213 95.5513 1.37213C96.4282 1.37213 97.2174 1.89809 97.5681 2.68702L107.28 25.0402C107.389 25.3251 107.521 25.61 107.521 25.9168V103.057C107.521 107.616 104.255 111.122 100.265 111.122H91.4956C87.3522 111.122 83.8446 107.374 83.8446 103.057V25.9168C83.8446 25.61 83.8446 25.3251 83.9542 25.0402L93.5344 2.68702ZM99.1685 17.5015L96.4939 18.7945C96.187 18.9479 95.8801 19.0136 95.5513 19.0136C95.2224 19.0136 94.8936 18.9479 94.6086 18.7945L91.934 17.5015L89.6322 22.8487L90.0048 22.4324C90.4652 21.9283 91.101 21.6653 91.7806 21.7092C92.4602 21.753 93.074 22.1036 93.4467 22.6734L95.6828 25.9826L97.6777 22.7611C98.0285 22.1913 98.6423 21.7968 99.3 21.7311C99.9796 21.6654 100.637 21.9064 101.12 22.3885L101.448 22.7173L99.1685 17.5015ZM103.136 30.6723L100.045 27.4509L97.8751 30.9572V87.4538H103.136V30.6723ZM93.4905 30.3655L91.4956 27.429L88.2291 31.0449V87.4538H93.4905V30.3655ZM103.136 91.8368H88.2291V94.4666H103.136V91.8368ZM95.5513 9.08616L93.6659 13.4691L95.5513 14.3677L97.4366 13.4691L95.5513 9.08616ZM91.4956 106.739H100.265C101.975 106.739 103.136 104.898 103.136 103.057V98.8496H88.2291V103.057C88.2291 104.964 89.7418 106.739 91.4956 106.739Z"
        fill="black"
      />
      <path
        d="M42.6298 3.74116C42.6298 2.53584 43.6163 1.54967 44.822 1.54967H64.1139C65.3196 1.54967 66.3062 2.53584 66.3062 3.74116V108.933C66.3062 110.138 65.3196 111.124 64.1139 111.124H44.822C43.6163 111.124 42.6298 110.138 42.6298 108.933V3.74116ZM47.0143 106.741H61.9216V102.358H56.5287C55.3229 102.358 54.3364 101.372 54.3364 100.167C54.3364 98.9613 55.3229 97.9752 56.5287 97.9752H61.9216V93.5922H59.1594C57.9536 93.5922 56.9671 92.606 56.9671 91.4007C56.9671 90.1954 57.9536 89.2092 59.1594 89.2092H61.9216V84.8262H56.5287C55.3229 84.8262 54.3364 83.8401 54.3364 82.6348C54.3364 81.4294 55.3229 80.4433 56.5287 80.4433H61.9216V76.0603H59.1594C57.9536 76.0603 56.9671 75.0741 56.9671 73.8688C56.9671 72.6635 57.9536 71.6773 59.1594 71.6773H61.9216V67.2943H56.5287C55.3229 67.2943 54.3364 66.3082 54.3364 65.1028C54.3364 63.8975 55.3229 62.9114 56.5287 62.9114H61.9216V58.5284H59.1594C57.9536 58.5284 56.9671 57.5422 56.9671 56.3369C56.9671 55.1316 57.9536 54.1454 59.1594 54.1454H61.9216V49.7624H56.5287C55.3229 49.7624 54.3364 48.7763 54.3364 47.5709C54.3364 46.3656 55.3229 45.3794 56.5287 45.3794H61.9216V40.9965H59.1594C57.9536 40.9965 56.9671 40.0103 56.9671 38.805C56.9671 37.5997 57.9536 36.6135 59.1594 36.6135H61.9216V32.2305H56.5287C55.3229 32.2305 54.3364 31.2443 54.3364 30.039C54.3364 28.8337 55.3229 27.8475 56.5287 27.8475H61.9216V23.4646H59.1594C57.9536 23.4646 56.9671 22.4784 56.9671 21.2731C56.9671 20.0677 57.9536 19.0816 59.1594 19.0816H61.9216V14.6986H56.5287C55.3229 14.6986 54.3364 13.7124 54.3364 12.5071C54.3364 11.3018 55.3229 10.3156 56.5287 10.3156H61.9216V5.93265H47.0143V106.741Z"
        fill="black"
      />
      <path
        d="M4.00276 34.4429C1.67896 32.1418 0.275919 28.7231 0.275919 24.6031C0.275919 21.7322 1.70087 19.3654 3.21353 16.8452C3.93697 15.618 4.70428 14.3688 5.31812 13.0101C7.07192 9.08734 6.58961 3.2799 6.58961 3.21416C6.50192 2.29373 7.00613 1.43902 7.81727 1.04455C8.65033 0.650082 9.63686 0.803497 10.2945 1.43903C10.4918 1.63626 15.2929 6.30414 19.3486 11.5856C21.0805 13.8429 25.0923 19.6284 25.0923 24.5812C25.0923 28.6573 23.3823 32.2294 20.642 34.5743C22.5273 39.4833 23.4481 49.3888 23.6235 51.5365C23.9962 55.0209 24.0619 60.675 23.755 64.1814L20.4228 102.795C20.0062 107.66 16.8056 110.904 12.443 110.904C8.05844 110.904 4.70426 107.573 4.30965 102.795L0.80204 64.1594C0.495123 60.675 0.560913 54.999 0.933598 51.5803C1.28436 47.5699 2.18319 38.9573 4.00276 34.4429ZM15.8629 14.2812C14.2187 12.1335 12.421 10.0735 10.8864 8.40797C10.6891 10.468 10.2507 12.791 9.32993 14.829C8.60649 16.4288 7.75152 17.8533 6.98423 19.1243C5.73464 21.1843 4.68236 22.9376 4.68236 24.5812C4.68236 28.5259 6.54578 33.106 11.8072 33.106H12.706C17.2659 33.106 20.7078 29.4682 20.7078 24.5812C20.7078 22.1267 18.8444 18.182 15.8629 14.2812ZM12.706 37.489H11.8072C10.3603 37.489 9.00111 37.226 7.75152 36.7658C6.89654 39.4394 6.04154 44.7209 5.5154 49.7614H19.0636C18.5813 45.3784 17.7482 39.6586 16.8055 36.7658C15.5121 37.226 14.1529 37.489 12.706 37.489ZM5.16464 63.7869L8.67226 102.423C8.82571 104.329 9.92186 106.543 12.443 106.543C15.4683 106.543 15.9725 103.409 16.0382 102.445L19.3705 63.8088C19.5897 61.2667 19.5897 57.4316 19.4143 54.1443H5.09889C4.94543 57.4316 4.94542 61.2448 5.16464 63.7869Z"
        fill="black"
      />
    </svg>
  );
};

export default Tools;
