import React from "react";

const darkmode = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.93992 4.48488C8.30992 12.0249 13.6899 16.8949 17.5999 18.2849C16.2099 19.3749 14.4799 19.9949 12.6699 19.9949C8.25992 19.9949 4.66992 16.4049 4.66992 11.9949C4.66992 8.54488 6.86992 5.59488 9.93992 4.48488ZM12.6599 2.00488C7.06992 2.00488 2.66992 6.53488 2.66992 11.9949C2.66992 17.5149 7.14992 21.9949 12.6699 21.9949C16.3799 21.9949 19.5999 19.9749 21.3299 16.9749C13.8199 16.7249 9.23992 8.54488 13.0099 2.00488C12.8899 2.00488 12.7799 2.00488 12.6599 2.00488Z"
        fill="white"
      />
    </svg>
  );
};

export default darkmode;
