import React from "react";


const TeamItems = ({ team }) => {
  return (
    <div className="md:basis-[30%] lg:basis-[15%] flex flex-col gap-5 items-center">
      <div className="w-[120px] h-[120px]">
        <img className="h-fit w-full" alt="Team-member" src={team.img} />
      </div>
      <div className="p-style text-lg xl:text-[23px] dark:text-white">{team.name}</div>
      <div className="p-style text-sm lg:text-[9px] xl:text-[11px] dark:text-white">
        {team.position}
      </div>
      <a href={team.link} target="_blank" rel="noreferrer">
        <div>
          {team.icon}
        </div>
      </a>
    </div>
  );
};

export default TeamItems;
