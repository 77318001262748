import React from "react";

const CaretDown = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5488 2C14.9588 2 18.5488 5.59 18.5488 10C18.5488 14.41 14.9588 18 10.5488 18C6.13883 18 2.54883 14.41 2.54883 10C2.54883 5.59 6.13883 2 10.5488 2ZM10.5488 0C5.02883 0 0.548828 4.48 0.548828 10C0.548828 15.52 5.02883 20 10.5488 20C16.0688 20 20.5488 15.52 20.5488 10C20.5488 4.48 16.0688 0 10.5488 0ZM10.5488 13L6.54883 9H14.5488L10.5488 13Z"
        fill="white"
      />
    </svg>
  );
};

export default CaretDown;
