import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Updates = () => {
  return (
    <div className="dark:bg-black padding-layout">
      <div className="hd-style text-center text-2xl screen600px:text-5xl pb-10 px-[10px]">
        VENTURE INTO THE FUTURE. QUALITY BEYOND 'AWESOME'
      </div>
      <div className="p-style text-sm screen600px:text-2xl text-center dark:text-white px-[10px] ">
        Zone 9 Survival" is a post-apocalyptic free-to-play MMO RPG 3D game
        built in Unreal Engine 5. The game features an immersive storyline
        licensed by the studio, advanced smart AI system for the NPCs, a guild
        system, an in-game e-sport concept, user-driven in-game economy and
        accepts cryptocurrency as a payment option.
      </div>
    </div>
  );
};

export default Updates;
