import React from "react";

export const Mission = () => {
  return (
    <div className="dark:bg-black padding-layout">
      <div id="mission" className="hd-style text-3xl sm:text-5xl text-center pb-10">MISSION</div>
      {/* <div className="p-style text-base sm:text-2xl font-bold dark:text-white">
        Hello Sipherians! Welcome to our Roadmap. As you may already know, the
        primary goal of Sipher is to create games that:
      </div>
      <div className="p-style text-base sm:text-2xl py-[10px] dark:text-white">
        To enrich people’s personalized experience in the modern era by mass
        adopting web 3 technology to web 2 platform.
      </div> */}
      <div className="p-style text-base sm:text-2xl dark:text-white text-center">
        To provide the people, communities and partners customizable experience
        that consistently aligned with QBA vision and value.
      </div>
    </div>
  );
};
