import React from "react";

const SmallLogo = () => {
  return (
    <svg
      width="150"
      height="50"
      viewBox="0 0 82 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.46031 10.8628L0.187439 12.1266L1.37932 13.3101L2.66435 12.0355C2.21486 11.6964 1.81126 11.3024 1.46031 10.8628Z"
        fill="white"
      />
      <path
        d="M1.68727 6.70261C1.68862 3.92698 3.95361 1.67671 6.74907 1.67537H11.2102C11.1198 1.59764 11.0307 1.52259 10.9402 1.45021C8.01924 -0.848297 3.77408 -0.36179 1.45915 2.53849C0.873332 3.27294 0.448141 4.12131 0.211923 5.02865C0.0715427 5.57547 2.64343e-06 6.13837 2.64343e-06 6.70261C-0.00134717 8.21172 0.514281 9.6766 1.4605 10.8574L2.66318 9.66186C2.02877 8.80411 1.68727 7.76676 1.68727 6.70261Z"
        fill="white"
      />
      <path
        d="M11.8107 6.70264C11.8107 9.47827 9.54575 11.7272 6.75029 11.7272C5.72173 11.7272 4.71747 11.4163 3.87249 10.8359L2.65765 12.0355C5.62184 14.279 9.85755 13.7121 12.1171 10.7676C13.0134 9.59889 13.4994 8.17154 13.498 6.70264H11.8107Z"
        fill="white"
      />
      <path
        d="M9.02484 3.35217H6.74905C4.88496 3.35217 3.37317 4.85056 3.37317 6.70143C3.37182 8.5523 4.88226 10.0534 6.74635 10.0534C8.61044 10.0547 10.1222 8.55498 10.1222 6.70411C10.1222 6.12781 9.97375 5.56223 9.68894 5.06098L9.72269 5.02613H13.2848C13.1323 4.4391 12.8988 3.87486 12.5924 3.35083H9.02484V3.35217ZM6.74905 8.37807C5.81633 8.37941 5.06044 7.62887 5.05909 6.70277C5.05774 5.77666 5.81363 5.02613 6.74635 5.02479C7.67907 5.02345 8.43497 5.77398 8.43632 6.70009C8.43632 6.70143 8.43632 6.70143 8.43632 6.70277C8.43632 7.62887 7.68042 8.37807 6.74905 8.37807Z"
        fill="white"
      />
      <path
        d="M22.018 10.0372L20.6358 13.3637H20.4211L19.0389 10.0372H19.2306C19.2509 10.0372 19.2698 10.0439 19.2846 10.056C19.2995 10.0681 19.3116 10.0841 19.3184 10.1016L20.4589 12.8598C20.4711 12.8946 20.4846 12.9321 20.4967 12.971C20.5089 13.0099 20.521 13.0501 20.5318 13.0903C20.5413 13.0501 20.5521 13.0099 20.5629 12.971C20.5737 12.9335 20.5858 12.896 20.6007 12.8598L21.7386 10.1016C21.7453 10.0841 21.7575 10.0681 21.7723 10.0573C21.7885 10.0439 21.8088 10.0372 21.829 10.0372H22.018Z"
        fill="white"
      />
      <path
        d="M24.5868 13.164V13.3637H22.5742V10.0372H24.5814V10.2356H22.8185V11.5758H24.2858V11.7702H22.8185V13.1573L24.5868 13.164Z"
        fill="white"
      />
      <path
        d="M27.9047 10.0372V13.3637H27.7913C27.7738 13.3637 27.7562 13.361 27.74 13.353C27.7238 13.3436 27.7117 13.3315 27.7009 13.3168L25.5263 10.4299C25.529 10.4741 25.529 10.5197 25.5263 10.5639V13.3637H25.313V10.0372H25.4345C25.4521 10.0372 25.4696 10.0399 25.4858 10.0466C25.5007 10.056 25.5142 10.0681 25.525 10.0828L27.6941 12.9616C27.6941 12.9147 27.6874 12.8705 27.6874 12.8276V10.0372H27.9047Z"
        fill="white"
      />
      <path
        d="M31.0714 10.2423H29.8957V13.3637H29.6514V10.2423H28.469V10.0372H31.0714V10.2423Z"
        fill="white"
      />
      <path
        d="M32.8614 13.1922C33.0099 13.1948 33.157 13.1654 33.2933 13.1077C33.4162 13.0555 33.5268 12.9764 33.6159 12.8772C33.7064 12.7753 33.7752 12.6561 33.8184 12.5274C33.867 12.388 33.8899 12.2419 33.8886 12.0945V10.0372H34.1302V12.0945C34.1316 12.2687 34.1019 12.443 34.0425 12.6078C33.9871 12.7619 33.9021 12.904 33.7901 13.0233C33.6767 13.1439 33.539 13.2391 33.3851 13.3021C33.045 13.4374 32.667 13.4374 32.3269 13.3021C32.173 13.2391 32.0353 13.1439 31.9219 13.0233C31.8112 12.9027 31.7248 12.7619 31.6695 12.6078C31.6101 12.443 31.5804 12.2687 31.5818 12.0945V10.0372H31.8261V12.0918C31.8247 12.2392 31.8477 12.3867 31.8963 12.5261C31.9395 12.6547 32.0083 12.7727 32.0974 12.8745C32.1865 12.975 32.2958 13.0541 32.4187 13.1077C32.5577 13.168 32.7089 13.1962 32.8614 13.1922Z"
        fill="white"
      />
      <path
        d="M37.39 13.3637H37.1821C37.1592 13.3637 37.1362 13.3597 37.1146 13.3503C37.093 13.3396 37.0755 13.3222 37.0634 13.3021L35.9835 11.9538C35.9714 11.9377 35.9579 11.9229 35.9444 11.9082C35.9309 11.8961 35.9147 11.8868 35.8985 11.8787C35.8796 11.8707 35.8607 11.8667 35.8404 11.864C35.8121 11.8626 35.7837 11.8626 35.7554 11.864H35.318V13.3691H35.0737V10.0372H35.9309C36.3156 10.0372 36.6058 10.1123 36.7961 10.2597C36.9932 10.4179 37.0998 10.6618 37.0836 10.9124C37.085 11.0317 37.0634 11.1496 37.0175 11.2595C36.9743 11.3641 36.9095 11.4579 36.8271 11.5356C36.7394 11.6187 36.6382 11.6844 36.5261 11.73C36.3992 11.7822 36.2656 11.8171 36.1293 11.8305C36.1698 11.8546 36.2049 11.8881 36.2319 11.9256L37.39 13.3637ZM35.9052 11.6804C36.0402 11.6817 36.1738 11.6643 36.3034 11.6281C36.4114 11.5973 36.5126 11.5463 36.6017 11.4766C36.6814 11.4123 36.7448 11.3319 36.788 11.2394C36.8312 11.1402 36.8528 11.033 36.8514 10.9258C36.8663 10.7234 36.7785 10.5264 36.6179 10.4004C36.4168 10.2731 36.1806 10.2141 35.943 10.2289H35.3302V11.6804H35.9052Z"
        fill="white"
      />
      <path
        d="M39.9625 13.164V13.3637H37.95V10.0372H39.9544V10.2356H38.1997V11.5758H39.6669V11.7702H38.1997V13.1573L39.9625 13.164Z"
        fill="white"
      />
      <path
        d="M42.2253 13.3637H41.9796V10.0372H42.2239L42.2253 13.3637Z"
        fill="white"
      />
      <path
        d="M45.7989 10.0372V13.3637H45.6856C45.668 13.3637 45.6505 13.361 45.6343 13.353C45.6181 13.3449 45.6046 13.3315 45.5951 13.3168L43.4206 10.4299C43.4206 10.4768 43.4273 10.5224 43.4273 10.5639V13.3637H43.2141V10.0372H43.3288C43.3463 10.0372 43.3639 10.0399 43.3801 10.0466C43.3963 10.0547 43.4098 10.0681 43.4192 10.0828L45.5897 12.9616C45.5843 12.9174 45.583 12.8718 45.583 12.8276V10.0372H45.7989Z"
        fill="white"
      />
      <path
        d="M48.966 10.2423H47.7836V13.3637H47.5406V10.2423H46.3568V10.0372H48.9606L48.966 10.2423Z"
        fill="white"
      />
      <path
        d="M52.3079 11.6992C52.3106 11.9377 52.2714 12.1736 52.1918 12.3988C52.1203 12.5971 52.0096 12.7794 51.8651 12.9349C51.7234 13.0836 51.5506 13.2002 51.3603 13.278C50.9432 13.4388 50.4789 13.4388 50.0618 13.278C49.8715 13.2002 49.6987 13.0836 49.557 12.9349C49.4125 12.7794 49.3019 12.5971 49.2303 12.3988C49.0751 11.9458 49.0751 11.4553 49.2303 11.0023C49.3019 10.8039 49.4125 10.6216 49.557 10.4662C49.6987 10.316 49.8701 10.1994 50.0618 10.1217C50.267 10.0373 50.487 9.99573 50.7097 9.99841C50.9324 9.99573 51.1538 10.0373 51.3603 10.1204C51.552 10.1981 51.7234 10.3147 51.8651 10.4648C52.0096 10.6203 52.1203 10.8026 52.1918 11.0009C52.2714 11.2261 52.3106 11.462 52.3079 11.6992ZM52.0568 11.6992C52.0595 11.4874 52.0271 11.2757 51.9596 11.0746C51.9029 10.9017 51.8112 10.7409 51.6897 10.6042C51.5709 10.4755 51.4251 10.375 51.2631 10.3093C50.9108 10.174 50.5194 10.174 50.1671 10.3093C50.0051 10.375 49.8593 10.4755 49.7405 10.6042C49.6164 10.7409 49.5219 10.9004 49.4625 11.0746C49.3329 11.4821 49.3329 11.919 49.4625 12.3264C49.5219 12.4993 49.6164 12.6588 49.7405 12.7942C49.8593 12.9228 50.0051 13.0233 50.1671 13.089C50.5194 13.223 50.9108 13.223 51.2631 13.089C51.4251 13.0233 51.5709 12.9228 51.6897 12.7942C51.8112 12.6574 51.9029 12.4993 51.9596 12.3264C52.0271 12.124 52.0595 11.9123 52.0568 11.6992Z"
        fill="white"
      />
      <path
        d="M56.4007 10.2423H55.2236V13.3637H54.9807V10.2423H53.7969V10.0372H56.4007V10.2423Z"
        fill="white"
      />
      <path
        d="M59.5483 10.0372V13.3637H59.304V11.7702H57.2078V13.3637H56.9621V10.0372H57.2064V11.5892H59.3027V10.0372H59.5483Z"
        fill="white"
      />
      <path
        d="M62.5489 13.164V13.3637H60.5377V10.0372H62.5449V10.2356H60.7807V11.5758H62.2493V11.7702H60.7807V13.1573L62.5489 13.164Z"
        fill="white"
      />
      <path
        d="M64.8124 10.2356V11.6335H66.3391V11.8332H64.8124V13.361H64.5681V10.0372H66.5753V10.2356H64.8124Z"
        fill="white"
      />
      <path
        d="M68.4668 13.1922C68.6153 13.1948 68.7624 13.1654 68.8987 13.1077C69.0216 13.0555 69.1323 12.9764 69.2213 12.8772C69.3118 12.7753 69.3806 12.6561 69.4238 12.5274C69.4724 12.388 69.4954 12.2419 69.494 12.0945V10.0372H69.7356V12.0945C69.737 12.2687 69.7073 12.443 69.6479 12.6078C69.5925 12.7619 69.5075 12.904 69.3955 13.0233C69.2821 13.1439 69.1444 13.2391 68.9905 13.3021C68.6504 13.4374 68.2724 13.4374 67.9323 13.3021C67.7784 13.2391 67.6407 13.1439 67.5273 13.0233C67.4166 12.9027 67.3303 12.7619 67.2749 12.6078C67.2155 12.443 67.1858 12.2687 67.1872 12.0945V10.0372H67.4315V12.0918C67.4301 12.2392 67.4531 12.3867 67.5017 12.5261C67.5449 12.6547 67.6137 12.7727 67.7028 12.8745C67.7919 12.975 67.9012 13.0541 68.0241 13.1077C68.1631 13.168 68.3143 13.1962 68.4668 13.1922Z"
        fill="white"
      />
      <path
        d="M72.8603 10.2423H71.6832V13.3637H71.4403V10.2423H70.2565V10.0372H72.8603V10.2423Z"
        fill="white"
      />
      <path
        d="M74.6447 13.1922C74.7931 13.1948 74.9403 13.1654 75.0766 13.1077C75.1994 13.0541 75.3101 12.9764 75.4005 12.8772C75.4896 12.7753 75.5585 12.6561 75.603 12.5274C75.6503 12.388 75.6732 12.2419 75.6719 12.0945V10.0372H75.9135V12.0945C75.9148 12.2701 75.8851 12.443 75.8257 12.6078C75.7704 12.7619 75.6854 12.904 75.5733 13.0233C75.4599 13.1439 75.3223 13.2391 75.1684 13.3021C74.8282 13.4374 74.4503 13.4374 74.1101 13.3021C73.9562 13.2391 73.8186 13.1439 73.7052 13.0233C73.5945 12.9027 73.5081 12.7619 73.4528 12.6078C73.3934 12.443 73.3637 12.2687 73.365 12.0945V10.0372H73.6094V12.0918C73.608 12.2392 73.6309 12.3867 73.6795 12.5261C73.7227 12.6547 73.7916 12.7727 73.8807 12.8745C73.9698 12.9737 74.0791 13.0541 74.2019 13.1077C74.3409 13.168 74.4921 13.1962 74.6447 13.1922Z"
        fill="white"
      />
      <path
        d="M79.1746 13.3637H78.964C78.941 13.3637 78.9181 13.3597 78.8965 13.3503C78.8749 13.3396 78.8574 13.3222 78.8452 13.3021L77.7654 11.9538C77.7532 11.9377 77.7411 11.9216 77.7262 11.9082C77.7127 11.8961 77.6979 11.8868 77.6817 11.8787C77.6628 11.8707 77.6425 11.8667 77.6223 11.864C77.5939 11.8626 77.5669 11.8626 77.5386 11.864H77.0999V13.3691H76.8569V10.0372H77.7127C78.0988 10.0372 78.3876 10.1123 78.5779 10.2597C78.775 10.4179 78.8817 10.6618 78.8655 10.9124C78.8668 11.0317 78.8452 11.1496 78.7993 11.2595C78.7561 11.3641 78.6913 11.4579 78.609 11.5356C78.5213 11.6187 78.42 11.6844 78.308 11.73C78.1824 11.7822 78.0488 11.8157 77.9125 11.8305C77.9516 11.8559 77.9867 11.8881 78.0137 11.9256L79.1746 13.3637ZM77.6898 11.6804C77.8261 11.683 77.9624 11.6656 78.0947 11.6281C78.2027 11.5973 78.3039 11.5463 78.393 11.4766C78.4727 11.4123 78.5361 11.3319 78.5793 11.2394C78.6225 11.1402 78.6441 11.033 78.6427 10.9258C78.6576 10.7234 78.5712 10.5264 78.4092 10.4004C78.2081 10.2731 77.9719 10.2141 77.7343 10.2289H77.1215V11.6804H77.6898Z"
        fill="white"
      />
      <path
        d="M81.75 13.164V13.3637H79.7374V10.0372H81.7446V10.2356H79.9817V11.5758H81.449V11.7702H79.9817V13.1573L81.75 13.164Z"
        fill="white"
      />
      <path
        d="M24.8677 6.06079C25.3307 5.85975 25.71 5.50995 25.9475 5.06767C26.0623 4.85189 26.1486 4.62271 26.2053 4.38549C26.262 4.14156 26.2917 3.89362 26.2917 3.64299C26.2985 3.13638 26.1972 2.63379 25.9934 2.16873C25.8044 1.73583 25.5291 1.34448 25.1835 1.02015C24.8312 0.694468 24.4182 0.438482 23.9687 0.268271C23.4922 0.0859988 22.9847 -0.00513747 22.4731 0.000223488C21.9629 -0.00245699 21.4553 0.0886793 20.9775 0.268271C20.5267 0.435801 20.1136 0.690447 19.7627 1.01613C19.4171 1.3418 19.1418 1.73449 18.9528 2.16873C18.7503 2.63379 18.6491 3.13638 18.6545 3.64299C18.645 4.16971 18.7449 4.69106 18.9474 5.17757C19.1323 5.61181 19.409 6.00048 19.7573 6.32079C20.1109 6.64245 20.5294 6.88637 20.9843 7.03782C21.4823 7.20669 22.0074 7.29113 22.5338 7.28711H26.8681V6.08089H24.8677V6.06079ZM24.4843 4.50477C24.375 4.76612 24.2157 5.002 24.0146 5.20303C23.8148 5.40005 23.5799 5.5582 23.3221 5.66944C22.7795 5.89594 22.1667 5.89594 21.6241 5.66944C21.3663 5.5582 21.1314 5.40005 20.933 5.20303C20.7318 5.00334 20.5726 4.76612 20.4632 4.50477C20.2324 3.95259 20.2324 3.33206 20.4632 2.77988C20.5726 2.51853 20.7318 2.28265 20.933 2.08296C21.1314 1.88594 21.3663 1.72779 21.6241 1.61655C22.1667 1.38737 22.7795 1.38737 23.3221 1.61655C23.5799 1.72779 23.8148 1.88594 24.0146 2.08296C24.645 2.72493 24.8285 3.67784 24.4843 4.50477Z"
        fill="white"
      />
      <path
        d="M32.8341 4.13887C32.6923 3.98608 32.525 3.86144 32.3387 3.76762C32.1376 3.66845 31.9202 3.60143 31.6975 3.57195V3.55184C32.0444 3.45401 32.3549 3.25699 32.5911 2.98492C32.8233 2.70481 32.9434 2.34965 32.9299 1.98779C32.9475 1.65943 32.8665 1.33241 32.6977 1.04962C32.5466 0.817756 32.3373 0.630122 32.0917 0.5028C31.8285 0.367436 31.5423 0.27898 31.248 0.241453C30.9389 0.198565 30.6271 0.175781 30.314 0.175781H27.6454V7.27905H30.5758C30.8971 7.27905 31.217 7.24554 31.5301 7.17853C31.8352 7.11822 32.1268 7.00564 32.394 6.84749C32.6478 6.69605 32.8624 6.48831 33.0217 6.24037C33.1918 5.95757 33.2755 5.6319 33.2633 5.3022C33.2647 5.08374 33.2269 4.86662 33.1526 4.66022C33.0811 4.46857 32.9731 4.29166 32.8341 4.13887ZM29.222 1.5053H30.2937C30.4193 1.5053 30.5434 1.51736 30.6663 1.54149C30.7864 1.56159 30.9025 1.60046 31.0105 1.65675C31.1104 1.70902 31.1954 1.78675 31.2575 1.88191C31.325 1.99181 31.3587 2.11913 31.3533 2.24779C31.3574 2.37377 31.3277 2.49842 31.2683 2.60966C31.2129 2.70749 31.1347 2.78925 31.0402 2.84956C30.943 2.91255 30.8363 2.95812 30.723 2.98358C30.6055 3.01039 30.4854 3.02379 30.3639 3.02379H29.222V1.5053ZM31.5855 5.51798C31.5247 5.62251 31.4397 5.71231 31.3385 5.77932C31.2359 5.84767 31.1212 5.89458 30.9997 5.92005C30.8782 5.94685 30.7554 5.96025 30.6312 5.96025H29.222V4.34527H30.4139C30.5529 4.34527 30.6906 4.35599 30.8282 4.37475C30.97 4.39352 31.1104 4.43104 31.2426 4.48465C31.3668 4.53558 31.4762 4.61599 31.5612 4.72053C31.6489 4.83579 31.6935 4.97786 31.6881 5.12261C31.6921 5.26199 31.6557 5.39869 31.5855 5.51798Z"
        fill="white"
      />
      <path
        d="M48.4435 3.5424C48.1951 3.39363 47.9292 3.27971 47.6511 3.20064C47.3677 3.12022 47.1031 3.04115 46.8574 2.96074C46.6401 2.89372 46.4336 2.79589 46.246 2.6699C45.9909 2.46753 45.9328 2.107 46.111 1.83628C46.1785 1.74112 46.2689 1.66472 46.3729 1.61112C46.4808 1.55348 46.5969 1.51194 46.7171 1.48513C46.8331 1.45967 46.9519 1.44627 47.0707 1.44493C47.2826 1.44761 47.4932 1.48781 47.6916 1.56153C47.8954 1.6272 48.0763 1.7505 48.2113 1.91669L49.2912 0.74398C48.9969 0.481293 48.6486 0.285618 48.2707 0.171698C47.8914 0.0577777 47.4959 0.000147453 47.0991 0.00148769C46.7535 0.00148769 46.4107 0.0497363 46.0786 0.146234C45.7627 0.23737 45.4671 0.384796 45.2053 0.583152C44.9529 0.777486 44.7463 1.02409 44.5992 1.30554C44.4413 1.61648 44.363 1.96092 44.3711 2.30804C44.3549 2.6431 44.4413 2.97548 44.6194 3.26095C44.7801 3.49817 44.992 3.69787 45.2404 3.84261C45.4941 3.99272 45.7668 4.11066 46.0503 4.19376C46.3391 4.28087 46.6091 4.36799 46.8601 4.4551C47.0829 4.52748 47.2921 4.63469 47.4824 4.7714C47.6444 4.88934 47.7362 5.07831 47.7294 5.27801C47.7321 5.39595 47.6984 5.51121 47.6336 5.60905C47.5674 5.70421 47.4797 5.78328 47.3785 5.83957C47.2678 5.90122 47.149 5.94679 47.0248 5.97359C46.6374 6.06339 46.2298 6.01648 45.8734 5.83957C45.6318 5.72565 45.4212 5.55544 45.262 5.34234L44.1389 6.57134C44.4588 6.87155 44.8368 7.10342 45.2498 7.25352C46.0084 7.50951 46.8264 7.53363 47.5998 7.32456C47.9184 7.23878 48.2167 7.09135 48.4786 6.893C48.7323 6.69732 48.9375 6.4467 49.0792 6.15989C49.2345 5.83689 49.31 5.48307 49.3019 5.12656C49.3195 4.77944 49.2345 4.43366 49.059 4.13345C48.9024 3.8922 48.6918 3.68983 48.4435 3.5424Z"
        fill="white"
      />
      <path
        d="M49.8135 1.56562H51.8544V7.28442H53.4296V1.56562H55.4705V0.181152H49.8135V1.56562Z"
        fill="white"
      />
      <path
        d="M60.7241 4.48454C60.7268 4.69362 60.6903 4.90136 60.6188 5.09703C60.5527 5.27663 60.4514 5.44282 60.3205 5.58354C60.1923 5.72025 60.037 5.83015 59.8656 5.9052C59.6847 5.98293 59.4877 6.0218 59.2906 6.02046C59.0908 6.0218 58.8924 5.98293 58.7088 5.9052C58.5347 5.83015 58.3781 5.72159 58.2499 5.58354C58.119 5.44282 58.0177 5.27663 57.9516 5.09703C57.8787 4.90136 57.8422 4.69362 57.8449 4.48454V0.183716H56.2697V4.54754C56.2657 4.94425 56.3305 5.33828 56.4614 5.7122C56.5829 6.05665 56.7772 6.3716 57.0324 6.63429C57.2983 6.90502 57.6209 7.11409 57.9772 7.24678C58.8236 7.54163 59.7468 7.54163 60.5932 7.24678C61.3045 6.98543 61.8606 6.42253 62.109 5.7122C62.2399 5.33828 62.3047 4.94425 62.3007 4.54754V0.183716H60.7241V4.48454Z"
        fill="white"
      />
      <path
        d="M39.462 0.833844C39.1961 0.563116 38.8735 0.355379 38.5171 0.221355C37.6694 -0.0721574 36.7475 -0.0721574 35.8998 0.221355C35.1885 0.482702 34.6324 1.0456 34.384 1.75593C34.2531 2.12986 34.1883 2.52389 34.1923 2.9206V7.2871H35.7702V4.24475H38.6494V7.2871H40.2246V2.9206C40.2287 2.52389 40.1639 2.12986 40.033 1.75593C39.9115 1.41149 39.7158 1.09787 39.462 0.833844ZM35.7702 3.04122V2.98091C35.7675 2.77183 35.804 2.56409 35.8755 2.36842C35.9417 2.18883 36.0429 2.02264 36.1738 1.88191C36.3034 1.74387 36.4587 1.63531 36.6328 1.56025C36.8164 1.48252 37.0148 1.44365 37.2146 1.44499C37.4116 1.44231 37.6087 1.48252 37.7896 1.56025C37.9623 1.63531 38.1162 1.74521 38.2445 1.88191C38.3754 2.02264 38.4766 2.18883 38.5428 2.36842C38.6157 2.56409 38.6521 2.77183 38.6494 2.98091V3.04122H35.7702Z"
        fill="white"
      />
      <path
        d="M69.1009 0.987796C68.6973 0.694284 68.2384 0.485207 67.7511 0.371286C67.2166 0.242623 66.6686 0.179632 66.1192 0.183653H63.7557V7.28692H66.3325C66.8495 7.28826 67.3637 7.21053 67.8578 7.0564C68.3234 6.91166 68.7594 6.68113 69.1401 6.37824C69.5126 6.07937 69.8136 5.70276 70.0242 5.27522C70.2523 4.80078 70.3644 4.28076 70.3536 3.75539C70.3698 3.18579 70.2537 2.61887 70.0148 2.1002C69.8042 1.66194 69.491 1.28131 69.1009 0.987796ZM68.5192 4.68954C68.3977 4.95357 68.2128 5.18409 67.9792 5.35966C67.7376 5.53657 67.4623 5.66121 67.1694 5.72688C66.836 5.80194 66.4958 5.83946 66.1543 5.83678H65.3309V1.62575H66.2596C66.5795 1.62441 66.8967 1.66596 67.2045 1.75173C67.4866 1.82679 67.7525 1.95411 67.9873 2.127C68.2114 2.29587 68.3923 2.51433 68.5178 2.76495C68.6541 3.04774 68.7216 3.35868 68.7149 3.6723C68.7257 4.0221 68.6582 4.36922 68.5192 4.69088V4.68954Z"
        fill="white"
      />
      <path d="M72.98 0.181152H71.4034V7.28308H72.98V0.181152Z" fill="white" />
      <path
        d="M81.4613 2.1942C81.279 1.74924 81.0023 1.34717 80.6514 1.01613C80.3018 0.687771 79.8887 0.433126 79.4365 0.268276C78.435 -0.0788459 77.3457 -0.0788459 76.3441 0.268276C75.8892 0.431785 75.4735 0.686431 75.1225 1.01613C74.7716 1.34717 74.4962 1.74924 74.3127 2.1942C73.9293 3.18866 73.9293 4.29034 74.3127 5.28479C74.4962 5.72975 74.7716 6.13182 75.1225 6.46286C75.4735 6.79256 75.8892 7.04721 76.3441 7.21072C77.3457 7.55918 78.435 7.55918 79.4365 7.21072C79.8887 7.04587 80.3018 6.79122 80.6514 6.46286C81.0023 6.13182 81.279 5.72975 81.4613 5.28479C81.846 4.29034 81.846 3.19 81.4613 2.1942ZM79.9562 4.65756C79.8577 4.92695 79.7052 5.17221 79.5067 5.37995C79.3083 5.58501 79.0694 5.74718 78.8048 5.85573C78.2163 6.08492 77.5644 6.08492 76.9758 5.85573C76.7113 5.74852 76.471 5.58635 76.2739 5.37995C76.0755 5.17221 75.923 4.92695 75.8244 4.65756C75.6152 4.06517 75.6152 3.41918 75.8244 2.82679C75.9216 2.55606 76.0755 2.30812 76.2739 2.09904C76.4724 1.89399 76.7113 1.73182 76.9758 1.62326C77.5644 1.39408 78.2163 1.39408 78.8048 1.62326C79.0694 1.73182 79.3083 1.89399 79.5067 2.09904C79.7052 2.30812 79.859 2.55606 79.9562 2.82679C80.1654 3.42052 80.1654 4.06517 79.9562 4.65756Z"
        fill="white"
      />
    </svg>
  );
};

export default SmallLogo;
