import { useBoolean } from 'ahooks';
import { Col } from 'antd';
import clsx from 'clsx';
import React from 'react';

const Product = ({ product }) => {
  const [isEntered, { setTrue, setFalse }] = useBoolean(false);

  return (
    <Col className="relative" span={24} xs={{ span: 8 }}>
      <div
        className="p-[0px] relative bg-white"
        onMouseEnter={setTrue}
        onMouseLeave={setFalse}
      >
        <img loading="lazy" width={'100%'} alt="" src={product.image} />
        <div
          className={clsx('absolute z-9', {
            'top-0 left-[0.1%] bottom-[2%] right-[2.5%]':
              product.url === 'https://docln.net/sang-tac/14516-dia-dang-so-9',
            'top-[1%] left-[1.5%] bottom-[3%] right-[3.5%]':
              product.url !== 'https://docln.net/sang-tac/14516-dia-dang-so-9',
            hidden: !isEntered,
          })}
        >
          <div className="flex justify-center items-center h-[100%] bg-[#05050580] rounded-[4%]">
            <a target="_blank" rel="noreferrer" href={product.url}>
              <button className="text-white text-[12px] font-[600] bg-[#4D6255] rounded-[5px] hover:bg-[#A6D1B6] hover:text-black px-[7px] py-[15px]">
                {product.linkTitle}
              </button>
            </a>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default Product;
