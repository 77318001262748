import React from "react";

const LightBulb = () => {
  return (
    <svg
      width="133"
      height="155"
      viewBox="0 0 133 155"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.7235 123.717H82.3064C83.6332 123.717 84.8158 122.823 85.1042 121.526C85.5368 119.738 85.9406 117.95 86.3444 116.249C86.7194 114.663 87.0655 113.135 87.4116 111.694C87.9019 109.675 88.2769 107.917 88.6518 106.331C89.8921 100.766 90.5843 97.7098 92.4591 94.2787C93.4109 92.5487 94.4492 90.7899 95.4299 89.1176C99.3814 82.4573 103.477 75.5663 103.477 66.4552C103.477 46.0993 86.8924 29.5205 66.5006 29.5205C61.1646 29.5205 56.0018 30.645 51.1562 32.8651C49.7141 33.5283 49.0795 35.2294 49.7429 36.6998C50.4063 38.1415 52.108 38.7758 53.579 38.1127C57.6458 36.2385 62.0011 35.3159 66.5006 35.3159C83.7197 35.3159 97.7085 49.2997 97.7085 66.484C97.7085 74.0093 94.1897 79.92 90.4689 86.2056C89.4306 87.9355 88.3923 89.7231 87.3828 91.5396C85.133 95.6915 84.3254 99.2091 83.0275 105.091C82.6814 106.648 82.2776 108.407 81.8161 110.396C81.47 111.838 81.1239 113.337 80.7489 114.952C80.5182 115.961 80.2874 117.114 80.0279 117.979H53.0021C52.7714 117.114 52.5406 115.99 52.3099 114.981C51.9349 113.366 51.5888 111.809 51.2427 110.367C49.0795 101.285 47.3489 96.1816 44.8973 91.4819C44.1474 90.0691 43.3398 88.6563 42.5322 87.2147C38.9845 80.9292 35.3215 74.413 35.3215 66.5417C35.3215 63.9755 35.6388 61.4383 36.2445 58.9875C36.6194 57.4305 35.6964 55.8736 34.1389 55.4988C32.5814 55.0951 31.0239 56.0466 30.649 57.6035C29.9279 60.5156 29.5529 63.5431 29.5529 66.5705C29.5529 75.9411 33.764 83.4376 37.5135 90.0691C38.2923 91.4819 39.0711 92.837 39.7921 94.221C42.013 98.4594 43.5994 103.188 45.6472 111.751C45.9933 113.193 46.3395 114.721 46.7144 116.336C47.1182 118.037 47.522 119.796 47.9546 121.583C48.2142 122.823 49.3968 123.717 50.7235 123.717Z"
        fill="black"
      />
      <path
        d="M50.8966 135.249H82.0469C83.6332 135.249 84.9312 133.952 84.9312 132.366C84.9312 130.78 83.6332 129.483 82.0469 129.483H50.8966C49.3103 129.483 48.0124 130.78 48.0124 132.366C48.0124 133.952 49.3103 135.249 50.8966 135.249Z"
        fill="black"
      />
      <path
        d="M62.8667 154.856H71.5196C72.6156 154.856 73.5963 154.279 74.0866 153.299L75.4134 150.675L80.4609 146.12C81.355 145.313 81.6434 144.015 81.2108 142.919C80.7781 141.795 79.7109 141.045 78.5284 141.045H55.0215C53.8101 141.045 52.7141 141.853 52.3103 143.006C51.9065 144.159 52.2526 145.457 53.2044 146.235L58.4538 150.531L60.4151 153.587C60.9631 154.395 61.8861 154.856 62.8667 154.856ZM69.7602 149.09H64.4531L63.0975 146.927C63.0686 146.869 63.0398 146.783 62.9821 146.783H71.0869C70.8562 147.071 70.6831 147.273 70.5389 147.533L69.7602 149.09Z"
        fill="black"
      />
      <path
        d="M114.351 68.3574H129.35C130.936 68.3574 132.234 67.06 132.234 65.4742C132.234 63.8884 130.936 62.5909 129.35 62.5909H114.351C112.765 62.5909 111.467 63.8884 111.467 65.4742C111.467 67.06 112.765 68.3574 114.351 68.3574Z"
        fill="black"
      />
      <path
        d="M3.59451 68.3574H18.5928C20.1791 68.3574 21.4771 67.06 21.4771 65.4742C21.4771 63.8884 20.1791 62.5909 18.5928 62.5909H3.59451C2.00816 62.5909 0.71023 63.8884 0.71023 65.4742C0.71023 67.06 2.00816 68.3574 3.59451 68.3574Z"
        fill="black"
      />
      <path
        d="M118.159 89.9832C119.283 89.9832 120.351 89.3201 120.812 88.1956C121.418 86.7251 120.726 85.024 119.255 84.4185L111.784 81.3334C110.313 80.7279 108.612 81.4199 108.006 82.8904C107.4 84.3608 108.092 86.062 109.563 86.6674L117.034 89.7525C117.438 89.9255 117.813 89.9832 118.159 89.9832Z"
        fill="black"
      />
      <path
        d="M21.7944 51.4032C22.9192 51.4032 23.9864 50.7401 24.4479 49.6156C25.0536 48.1451 24.3614 46.444 22.8904 45.8385L15.4201 42.7534C13.9491 42.1479 12.2474 42.8399 11.6417 44.3104C11.036 45.7809 11.7282 47.482 13.1992 48.0875L20.6695 51.1726C21.0444 51.3456 21.4194 51.4032 21.7944 51.4032Z"
        fill="black"
      />
      <path
        d="M48.561 26.3478C48.9359 26.3478 49.3109 26.2901 49.657 26.1171C51.128 25.5116 51.8202 23.8105 51.2145 22.34L48.1283 14.8724C47.5226 13.4019 45.8209 12.7099 44.3499 13.3154C42.8789 13.9209 42.1867 15.622 42.7924 17.0925L45.8786 24.5601C46.3401 25.6846 47.4072 26.3478 48.561 26.3478Z"
        fill="black"
      />
      <path
        d="M84.0373 25.917C85.1621 25.917 86.2293 25.2538 86.6908 24.1294L89.777 16.6617C90.3827 15.1912 89.6905 13.4901 88.2195 12.8846C86.7485 12.2791 85.0468 12.9711 84.4411 14.4416L81.3549 21.9093C80.7492 23.3797 81.4414 25.0808 82.9124 25.6863C83.2874 25.8305 83.6623 25.917 84.0373 25.917Z"
        fill="black"
      />
      <path
        d="M110.256 51.4032C110.631 51.4032 111.006 51.3456 111.352 51.1726L118.822 48.0875C120.293 47.482 120.985 45.7809 120.38 44.3104C119.774 42.8399 118.072 42.1479 116.601 42.7534L109.131 45.8385C107.66 46.444 106.968 48.1451 107.573 49.6156C108.064 50.7401 109.131 51.4032 110.256 51.4032Z"
        fill="black"
      />
      <path
        d="M14.7855 89.9832C15.1605 89.9832 15.5354 89.9255 15.8815 89.7525L23.3518 86.6674C24.8228 86.062 25.515 84.3608 24.9093 82.8904C24.3036 81.4199 22.6019 80.7279 21.1309 81.3334L13.6606 84.4185C12.1896 85.024 11.4974 86.7251 12.1031 88.1956C12.5646 89.3201 13.6606 89.9832 14.7855 89.9832Z"
        fill="black"
      />
      <path
        d="M111.467 112.906C112.217 112.906 112.938 112.618 113.515 112.07C114.64 110.946 114.64 109.129 113.515 108.005L102.901 97.3942C101.776 96.2698 99.9591 96.2698 98.8342 97.3942C97.7094 98.5187 97.7094 100.335 98.8342 101.46L109.448 112.07C109.996 112.618 110.746 112.906 111.467 112.906Z"
        fill="black"
      />
      <path
        d="M20.2375 112.906C20.9874 112.906 21.7085 112.618 22.2854 112.07L32.8995 101.46C34.0244 100.335 34.0244 98.5187 32.8995 97.3942C31.7746 96.2698 29.9576 96.2698 28.8327 97.3942L18.2185 108.005C17.0936 109.129 17.0936 110.946 18.2185 112.07C18.7665 112.618 19.5164 112.906 20.2375 112.906Z"
        fill="black"
      />
      <path
        d="M98.8918 33.7874C99.6417 33.7874 100.363 33.4991 100.94 32.9513L111.554 22.3409C112.679 21.2164 112.679 19.3999 111.554 18.2755C110.429 17.151 108.612 17.151 107.487 18.2755L96.8728 28.8859C95.7479 30.0103 95.7479 31.8268 96.8728 32.9513C97.4208 33.4991 98.1707 33.7874 98.8918 33.7874Z"
        fill="black"
      />
      <path
        d="M66.645 21.736C68.2314 21.736 69.5293 20.4385 69.5293 18.8527V3.85971C69.5293 2.27391 68.2314 0.97644 66.645 0.97644C65.0587 0.97644 63.7607 2.27391 63.7607 3.85971V18.8527C63.7607 20.4385 65.0587 21.736 66.645 21.736Z"
        fill="black"
      />
      <path
        d="M64.8277 83.1781C65.5777 83.1781 66.2699 82.8897 66.8179 82.3707L85.9407 64.1773C87.0944 63.0817 87.1521 61.2652 86.056 60.1119L78.5858 52.1252C78.0666 51.5486 77.3167 51.2314 76.5379 51.2026C75.788 51.1738 75.0092 51.4621 74.4612 52.0099L65.6353 60.5156L28.3416 17.6991C27.8224 17.1224 27.1014 16.7476 26.3226 16.7187C25.5438 16.6899 24.7939 16.9494 24.2171 17.4684L16.1699 24.8207C15.0162 25.8875 14.9008 27.6752 15.9392 28.8573L62.6645 82.1977C63.1837 82.8032 63.9048 83.1492 64.7124 83.1781C64.7412 83.1781 64.7989 83.1781 64.8277 83.1781ZM79.8837 61.986L65.0296 76.1429L22.1404 27.185L25.9477 23.7251L63.2991 66.6281C63.8183 67.2336 64.5682 67.5796 65.3469 67.6084C66.1545 67.6372 66.9044 67.3489 67.4813 66.8011L76.3649 58.209L79.8837 61.986Z"
        fill="black"
      />
    </svg>
  );
};

export default LightBulb;
